import { PlacesListWithFilter } from "../components/places/PlacesListWithFilter";
import { StyledContent } from "../components/styles/styled.Content";

export function PlacesListPage() {
  return (
    <StyledContent>
      <PlacesListWithFilter />
    </StyledContent>
  );
}
