import { skipToken } from "@reduxjs/toolkit/dist/query";
import React from "react";
import useTranslationService from "../../services/TranslationService";
import { useUserService } from "../../services/UserService";
import { IPlacelist } from "../../stuff/AppModelTypes";
import { isArray } from "../../utils/Utils";
import SmartAccordion from "../accordion/SmartAccordion";
import SmartAttribute from "../others/SmartAttribute";
import { UserComponent } from "../users/UserComponent";
import { UserdataPlacelistsRole } from "../../api/DbConstants";
import { useGetUsersForPlacelist } from "../../services/RelationsServices";

interface IProps {
  placelist: IPlacelist;
}

function PlacelistUsersComponent({ placelist }: IProps): JSX.Element | null {
  const { t, tCapitalize } = useTranslationService();
  let ret = null;
  const plUsers = useGetUsersForPlacelist(placelist ?? skipToken).data?.records;
  const { userId } = useUserService();
  let myRole;
  let allUsersCount = 0;
  let adminUsersCount = 0;
  let editorUsersCount = 0;
  let viewerUsersCount = 0;
  if (placelist != null) {
    // const plUsers: IPlacelistUser[] = placelist.Users;
    if (plUsers != null) {
      allUsersCount = plUsers.length;
      for (let i = 0; i < plUsers.length; i++) {
        const oneUser: any = plUsers[i];
        const id = oneUser.Id;
        const userRole = oneUser.Role;
        if (id === userId) {
          myRole = userRole;
        }
        if (userRole === UserdataPlacelistsRole.Admin) {
          adminUsersCount++;
        }
        if (userRole === UserdataPlacelistsRole.Editor) {
          editorUsersCount++;
        }
        if (userRole === UserdataPlacelistsRole.Viewer) {
          viewerUsersCount++;
        }
      }
    }
    let usersList;
    if (plUsers != null && isArray(plUsers)) {
      usersList = plUsers.map((u: any) => {
        // const text = u.UserId + "(" + u.Role + ")";
        return <UserComponent user={u} />;
      });
    }

    ret = (
      <>
        {usersList != null && usersList}
        <SmartAccordion header={t("information")}>
          <SmartAttribute
            key="admins"
            label={tCapitalize("administrators")}
            value={adminUsersCount}
          />
          <SmartAttribute
            key="editors"
            label={tCapitalize("editors")}
            value={editorUsersCount}
          />
          <SmartAttribute
            key="viewers"
            label={tCapitalize("viewers")}
            value={viewerUsersCount}
          />
          <SmartAttribute key="all" label={t("all")} value={allUsersCount} />
          <SmartAttribute key="myRole" label={t("my-role")} value={myRole} />
        </SmartAccordion>
      </>
    );
  }

  return ret;
}

export default PlacelistUsersComponent;
