import { Typography } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/query";
import React, { useEffect, useState } from "react";
import { useGetScrapeLinksFilteredQuery } from "../api/PlacesApiSlice";
import SmartAccordion from "../components/accordion/SmartAccordion";
import { StyledContent } from "../components/styles/styled.Content";
import PlacesListFromIds from "../components/places/PlacesListFromIds";
import { useTranslation } from "react-i18next";

const PARTS_CONFIGS = [
  { name: "0 errors", min: 0, max: 0 },
  { name: "1 error", min: 1, max: 1 },
  { name: "2 errors", min: 2, max: 2 },
  { name: "3 errors", min: 3, max: 3 },
  { name: "4 errors", min: 4, max: 4 },
  { name: "5 errors", min: 5, max: 5 },
  { name: "6 - 10  errors", min: 6, max: 10 },
  { name: "11 - 100  errors", min: 11, max: 100 },
  { name: "more than 101 errors", min: 101 },
  { name: "others" },
];

export function LostPlacesPage() {
  const { t } = useTranslation();
  const queryArgs = {
    // searchString: "saint-laurent",
    onlyWithStatus: true,
    maxResults: 5000,
  };
  const {
    data: scrapeLinksData,
    /*isLoading,*/
    // isFetching,
    // isSuccess,
    // isError,
    // error,
  } = useGetScrapeLinksFilteredQuery(queryArgs ?? skipToken);
  const [placesModel, setPlacesModel] = useState();

  useEffect(() => {
    if (scrapeLinksData) {
      for (let i = 0; i < PARTS_CONFIGS.length; i++) {
        const pConf = PARTS_CONFIGS[i];
        pConf.list = [];
      }
      // const errorCounterlessThanOne = [];
      // const errorCounterEqualsOne = [];
      // const errorCounterEqualsTwo = [];
      // const errorCounterGreaterTwo = [];
      const sls = scrapeLinksData.records;
      for (let i = 0; i < sls.length; i++) {
        const scrapeLink = sls[i];
        addToAccordingConfig(scrapeLink);
      }
      const pListsModel = PARTS_CONFIGS.map((conf) => {
        return _createListModel(conf.name, conf.list);
      });
      // [
      //   _createListModel("more than 2 errors", errorCounterGreaterTwo),
      //   _createListModel("exactly 2 errors", errorCounterEqualsTwo),
      //   _createListModel("exactly 1 error", errorCounterEqualsOne),
      //   _createListModel("without errors", errorCounterlessThanOne),
      // ];
      setPlacesModel(pListsModel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrapeLinksData]);

  function addToAccordingConfig(scrapeLink) {
    const statusString = scrapeLink.Status;
    if (statusString != null) {
      const status = JSON.parse(statusString);
      const errCount = status.errorCounter;
      for (let index = 0; index < PARTS_CONFIGS.length; index++) {
        const config = PARTS_CONFIGS[index];
        if (_fitsConfig(errCount, config)) {
          config.list.push(scrapeLink.PlaceId);
          break;
        }
      }
    }
  }

  let PList = <Typography>Laden...</Typography>;

  if (placesModel != null) {
    PList = (
      <SmartAccordion
        header={t("all") + " (" + _calculateSumOfAll(placesModel) + ")"}
        defaultExpanded={true}
      >
        {placesModel.map((list) => {
          return (
            list.count > 0 && (
              <SmartAccordion key={list.name} header={list.name}>
                <PlacesListFromIds
                  key={list.name}
                  placeIds={list.reducedList}
                  infoVisibility="collapsed"
                />
              </SmartAccordion>
            )
          );
        })}
      </SmartAccordion>
    );
  }

  return <StyledContent>{PList}</StyledContent>;
}

function _createListModel(name, list) {
  const reducedList = list.slice(0, 25);
  return {
    name: name + " (" + list.length + ")",
    count: list.length,
    list: list,
    reducedList: reducedList,
  };
}

function _calculateSumOfAll(model) {
  let ret = 0;
  for (let index = 0; index < model.length; index++) {
    const element = model[index];
    ret = ret + element.list.length;
  }
  return ret;
}

function _fitsConfig(number, config) {
  if (config.min != null && config.min > number) {
    return false;
  }
  if (config.max != null && config.max < number) {
    return false;
  }
  return true;
}
