import { Card, CardActionArea, CardMedia } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { isNullOrUndefined } from "../utils/Utils";
import { generateSXForTextBorder } from "./MuiUtils";
import { SmartOverlayText } from "./others/SmartOverlayText";
import SmartTooltip from "./others/SmartTooltip";
import { sxForCard } from "../stuff/StyleConstants";

interface IProps {
  text: string;
  tooltip?: string;
  icon?: any;
  image?: string;
  href: string;
}

export default function Kachel({
  text,
  tooltip,
  icon,
  image,
  href,
}: IProps): JSX.Element | null {
  if (!tooltip) {
    tooltip = text;
  }
  const navigate = useNavigate();
  function _click(event: any): void {
    navigate(href);
  }

  let cardMedia = null;
  if (isNullOrUndefined(image)) {
    const width = Math.max(140, Math.min(800, text.length * 30));
    image = "https://picsum.photos/" + width + "/140";
  }
  cardMedia = (
    <CardMedia
      component="img"
      // image="https://picsum.photos/400/300"
      image={image}
      // alt="CardMedia Image Example"
      height="140"
      // title="CardMedia Image Example"
      style={{ filter: "blur(1px)" }}
    />
  );

  let textShadow =
    generateSXForTextBorder("2px", "0", "rgba(0, 0, 0, .5)") + ", ";
  textShadow = textShadow + "5px 7px 7px rgba(0, 0, 0, 1)";
  return (
    <SmartTooltip
      title={tooltip}
      style={{
        flexGrow: 1,
        flexShrink: 1,
      }}
    >
      <Card
        style={{
          position: "relative",
          width: "auto",
          margin: 10,
          flexGrow: 1,
        }}
        raised={true}
        elevation={5}
        sx={sxForCard}
      >
        <CardActionArea component="span" onClick={_click}>
          {cardMedia}
          <SmartOverlayText
            text={text}
            textColor="white"
            textShadow={textShadow}
          />
        </CardActionArea>
      </Card>
    </SmartTooltip>
  );
}
