import React, { useState } from "react";
import SmartAccordion from "../accordion/SmartAccordion";
import ScrapeLinksQueryList from "./ScrapeLinksQueryList";

interface IProps {
  header: string;
  queryArguments: any;
}

export function ScrapeLinksQueryListAccordion(props: IProps) {
  const [count, setCount] = useState<number | undefined | null>();

  function handleCount(count: number | null): void {
    if (count != null) {
      setCount(count);
    }
  }
  let header = props.header;
  if (count != null) {
    header = header + " (" + count + ")";
  }
  return count !== 0 ? (
    <SmartAccordion key={header} header={header} disabled={count === 0}>
      <ScrapeLinksQueryList
        updateCount={handleCount}
        queryArguments={props.queryArguments}
      />
    </SmartAccordion>
  ) : null;
}
