import React from "react";
import { IPlace } from "../../stuff/AppModelTypes";
import { findCountryBoundsForPlaces } from "./LeafletUtils";
import LocationsInAreaImage from "./LocationsInAreaImage";
import { ICoord, makeSmartDefaults } from "./MapUtils";
import { MarkerVisualizations } from "./SvgUtils";

interface IProps {
  places: IPlace[];
  markerVisualization?: MarkerVisualizations;
  disableMarkerClustering?: boolean;
  width?: string;
  height?: string;
}

export function CountriesMapForPlaces({
  places,
  markerVisualization = MarkerVisualizations.dot,
  disableMarkerClustering = false,
  width,
  height,
}: IProps): JSX.Element | null {
  ({ width, height } = makeSmartDefaults(width, height));
  const countryBounds = findCountryBoundsForPlaces(places);
  const coords = [] as ICoord[];
  for (let index = 0; index < places.length; index++) {
    const p = places[index];
    const lat = p.Latitude;
    const lon = p.Longitude;
    if (lat != null && lon != null) {
      coords.push({ lat, lon });
    }
  }

  let ret = null;

  if (countryBounds != null) {
    ret = (
      <LocationsInAreaImage
        bounds={countryBounds}
        locations={coords}
        markerVisualization={markerVisualization}
        disableMarkerClustering={disableMarkerClustering}
        width={width}
        height={height}
      />
    );
  }

  return ret;
}
