import React from "react";
import { BLACK_WATER_DISPOSAL } from "../../../api/FeaturesConstants";
import { FeatureChip, FeatureChipSubtypeProps } from "./FeatureChip";

export function BlackWaterDisposalChip({
  feature,
  available,
}: FeatureChipSubtypeProps) {
  return (
    <FeatureChip
      feature={feature}
      featureConst={BLACK_WATER_DISPOSAL}
      available={available}
    />
  );
}
