import { ExpandMoreTwoTone } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { isArrayEmpty } from "../../utils/Utils";
import SmartTooltip from "../others/SmartTooltip";

interface IProps {
  header: string;
  subheader?: string;
  children: JSX.Element | JSX.Element[];
  defaultExpanded?: boolean;
  onExpand?: any;
  disabled?: boolean;
}

function SmartAccordion({
  header,
  subheader,
  children,
  defaultExpanded,
  onExpand,
  disabled,
}: IProps): JSX.Element | null {
  const { i18n } = useTranslation();
  if (defaultExpanded == null) {
    defaultExpanded = false;
  }
  let tooltipText = header;
  if (_isSubheaderVisible()) {
    tooltipText = tooltipText + " - " + subheader;
  }

  const headerComp = (
    <Typography>{i18n.format(header, "capitalize")}</Typography>
  );
  const subheaderComp = _isSubheaderVisible() ? (
    <Typography
      // lineHeight="110%"
      fontStyle="italic"
      sx={{ opacity: 0.5 }}
    >
      {subheader}
    </Typography>
  ) : null;
  const summaryContent = (
    <Stack
      flexGrow={1}
      display="flex"
      flexDirection="row"
      flexWrap="wrap"
      columnGap={2}
    >
      {headerComp}
      {subheaderComp}
    </Stack>
  );

  function handleChange(event: any, expanded: boolean): void {
    if (onExpand != null) {
      onExpand();
    }
    event?.stopPropagation();
  }

  const ret = (
    <Accordion
      // key={header}
      sx={{ flexGrow: 1, background: "none" }}
      defaultExpanded={defaultExpanded}
      onChange={handleChange}
      disabled={disabled || children == null || isArrayEmpty(children)}
    >
      <SmartTooltip key={header} title={tooltipText}>
        <AccordionSummary
          expandIcon={<ExpandMoreTwoTone />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          disableRipple
          sx={{
            minHeight: "0 !important",
            "&& .MuiAccordionSummary-content": {
              my: "4px",
            },
          }}
        >
          {summaryContent}
        </AccordionSummary>
      </SmartTooltip>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );

  return ret;

  // Helpers
  function _isSubheaderVisible() {
    return subheader != null && subheader.length > 0;
  }
}

export default SmartAccordion;
