import { MapTwoTone } from "@mui/icons-material";
import React from "react";
import useTranslationService from "../../services/TranslationService";
import { SmartChip } from "../chip/SmartChip";
import {
  Coordinates,
  getGoogleMapsUrlForCoords,
  getTooltipTextForCoords,
} from "./NavigationUtils";

type Props = {
  coords: Coordinates;
  inaccurate?: boolean;
};

export function GpsChip({ coords, inaccurate = false }: Props) {
  const { tCapitalize } = useTranslationService();
  const tooltipText = getTooltipTextForCoords(coords, inaccurate);
  const href = getGoogleMapsUrlForCoords(coords);

  const chip = (
    <SmartChip
      icon={<MapTwoTone />}
      // text={getCoordinatesText(coords, 2)}
      text={tCapitalize("google-maps")}
      href={href != null ? href : undefined}
      target="_blank"
      tooltip={tooltipText}
    />
  );
  return chip;
}
