import React from "react";
import { Virtuoso } from "react-virtuoso";

type Props = {
  data: any[];
  renderComponent: (element: any, index: number) => any;
  endReached?: (index: number) => void;
  increaseViewportBy?: number;
  // components?: Components;
  footer?: any;
};

export function SmartList({
  data,
  renderComponent,
  endReached,
  increaseViewportBy = 100,
  footer,
}: Props) {
  const components = { Footer: footer };
  return (
    <>
      <Virtuoso
        data={data}
        endReached={endReached}
        itemContent={renderComponent}
        components={components}
        increaseViewportBy={increaseViewportBy}
        useWindowScroll
      />
    </>
  );
}
