import React from "react";
import { TOILETS } from "../../../api/FeaturesConstants";
import { FeatureChip, FeatureChipSubtypeProps } from "./FeatureChip";

export function ToiletsChip({ feature, available }: FeatureChipSubtypeProps) {
  return (
    <FeatureChip
      feature={feature}
      featureConst={TOILETS}
      available={available}
    />
  );
}
