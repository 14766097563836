import React from "react";
import { AbstractScrapeLinksSincePage } from "./AbstractScrapeLinksSincePage";

export function UpdatedScrapeLinksSincePage() {
  return (
    <AbstractScrapeLinksSincePage
      titleKey={"updated-scrape-links-from-the-last-time"}
      queryKey={"fromUpdatedAt"}
    />
  );
}
