import { getPlaces } from "./PlacesApi";

const MAX_CACHE_SIZE = 2000;
const PAGE_SIZE = 250;
let cacheReplacement = [];

export const retrievePlacesWithCallback = async (props, callback) => {
  const LAST_CALL_TIME_KEY = "LAST_CALL_TIME";
  const lastCallTime = Date.now();
  sessionStorage.setItem(LAST_CALL_TIME_KEY, lastCallTime);
  const maxSize = props.maxSize;
  if (props.resetCache === true) {
    cacheReplacement = [];
  }
  const cache = cacheReplacement;
  const cacheFiltered = _reduce(cache, props);
  callback(cacheFiltered);
  if (maxSize && cacheFiltered.length >= maxSize) {
    return;
  }
  props.pageSize = PAGE_SIZE;
  // const maxPages = Math.ceil(maxSize / PAGE_SIZE);
  for (let i = 1; true; i++) {
    props.page = i;
    const newPlaces = await getPlaces(props);
    if (!newPlaces || newPlaces.length < 1) {
      return;
    }
    const newPlacesFiltered = _reduce(newPlaces, props);
    const newCache = _addToCache(newPlacesFiltered);
    const newCacheFiltered = _reduce(newCache, props);
    {
      const storedTime = +sessionStorage.getItem(LAST_CALL_TIME_KEY);
      if (storedTime && storedTime !== lastCallTime) {
        return;
      }
    }
    callback(newCacheFiltered);
    if (maxSize && newCacheFiltered.length >= maxSize) {
      return;
    }
  }
};

function _addToCache(list) {
  let cachedList = _careForCache();
  for (let i = 0; i < list.length; i++) {
    const newElement = list[i];
    newElement.cacheUpdateTime = Date.now();
    let foundInCache = false;
    for (let j = 0; j < cachedList.length; j++) {
      const elementFromCache = cachedList[j];
      if (elementFromCache.Id === newElement.Id) {
        foundInCache = true;
        cachedList[j] = newElement;
        break;
      }
    }
    if (!foundInCache) {
      if (cachedList.length >= MAX_CACHE_SIZE) {
        cachedList = _cutTheOldestElements(cachedList, MAX_CACHE_SIZE - 1);
      }
      cachedList.push(newElement);
    }
  }
  return cachedList;
}
function _careForCache() {
  let cachedList = cacheReplacement;
  _cutTheOldestElements(cachedList, MAX_CACHE_SIZE);
  cacheReplacement = cachedList;
  return cachedList;
}
function _cutTheOldestElements(list, maxSize) {
  if (list.length > maxSize) {
    function sortPlacesInCacheForCacheAge(a, b) {
      const timeA = a.cacheUpdateTime;
      const timeB = b.cacheUpdateTime;
      if (!timeA) {
        if (!timeB) {
          return 0;
        } else {
          return 1;
        }
      } else {
        if (!timeB) {
          return -1;
        } else {
          return timeB - timeA;
        }
      }
    }
    list = list.sort(sortPlacesInCacheForCacheAge);
    list.length = maxSize;
  }
  return list;
}
function _reduce(list, props) {
  if (list == null || list.length === 0) {
    return list;
  }
  list = [...list];
  if (props.maxSize && list.length > props.maxSize) {
    list.length = props.maxSize;
  }
  _sortPlaces(list);
  return list;
}
function _sortPlaces(list) {
  list.sort((a, b) => {
    return a.Id - b.Id;
  });
}
