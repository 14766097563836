import React, { useEffect, useState } from "react";
import { getFeatureConstFromKey } from "../../api/ApiUtil";
import { recallProviders, storeProviders } from "../../services/SettingsService";
import useTranslationService from "../../services/TranslationService";
import { isJSONString } from "../../utils/Utils";
import MultiCheckboxComponent from "../others/MultiCheckboxComponent";
import { INITIAL_PROVIDER_OPTIONS } from "../places/PlacesConstants";

export function ProviderSettingsForm(): JSX.Element | null {
  const { t } = useTranslationService();

  function initSettings() {
    const storedProviders = recallProviders();
    const initialProvidersSorted = INITIAL_PROVIDER_OPTIONS; //.sort(compareProviders);
    let ret = initialProvidersSorted;
    if (storedProviders != null && isJSONString(storedProviders)) {
      const storedFeatureArray = JSON.parse(storedProviders);
      ret = initialProvidersSorted.map((f) => {
        const domain = f.domain;
        const storedFilterd = storedFeatureArray.filter(
          (o: { domain: string }) => {
            return domain === o.domain;
          }
        );
        if (storedFilterd.length > 0) {
          const theOne = storedFilterd[0];
          if (theOne.checked != null) {
            f.checked = theOne.checked;
          }
        }
        return f;
      });
    }
    return ret;
  }
  const [providers, setProviders] = useState(initSettings);

  useEffect(() => {
    storeProviders(providers);
  }, [providers]);
  const featureEntries = _providers2CheckboxElements(providers);

  function changeProviders(elements: any) {
    setProviders(_checkboxElements2Providers(elements));
  }

  function _providers2CheckboxElements(providers: any[]) {
    const ret = [];
    for (let i = 0; i < providers.length; i++) {
      const f = providers[i];
      let name = f.domain;
      let icon = null;
      const fc = getFeatureConstFromKey(f.domain);
      if (fc != null) {
        icon = fc.icon;
        const tk = fc.translationKey;
        if (tk == null) {
          break;
        }
        name = t(tk);
      }
      const newElement = {
        id: f.domain,
        label: name,
        checked: f.checked,
        icon: icon,
      };
      ret.push(newElement);
    }
    return ret;
  }
  function _checkboxElements2Providers(checkboxElements: any[]) {
    const ret = [];
    for (let i = 0; i < checkboxElements.length; i++) {
      const e = checkboxElements[i];
      const newServices = { domain: e.id, name: e.label, checked: e.checked };
      ret.push(newServices);
    }
    return ret;
  }

  return (
    <MultiCheckboxComponent
      key="Providers"
      entries={featureEntries}
      onChange={changeProviders}
    />
  );
}
