import { LocationOnTwoTone } from "@mui/icons-material";
import React from "react";
import { navigateToUrl, stripDomainFromUrl } from "../../../utils/Utils";
import { makeBetterUrl } from "../../../utils/Utils";
import SmartButton from "./../../buttons/SmartButton";

interface INavigateToProviderButtonProps {
  scrapeLink: any;
}

function NavigateToProviderButton({
  scrapeLink,
}: INavigateToProviderButtonProps): JSX.Element | null {
  let ret = null;
  if (scrapeLink) {
    // return <ScrapeLink key={sl.Id} scrapeLink={sl} />;
    const url = scrapeLink.Url;
    const p = stripDomainFromUrl(url);
    const betterUrl = makeBetterUrl(url);
    const tooltipText = "Direkt zu " + p;

    return (
      <SmartButton
        key={tooltipText}
        text={tooltipText}
        onMouse={(event: any) => {
          navigateToUrl(betterUrl);
          event.stopPropagation();
        }}
      >
        <LocationOnTwoTone />
      </SmartButton>
    );
  }
  return ret;
}

export default NavigateToProviderButton;
