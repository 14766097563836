import { useMemo } from "react";
import { MENU_STRUCTURE, VisibleE } from "../stuff/AppConstants";
import { useUserService } from "./UserService";

function useMenuService() {
  const { isAuthenticated, isSuperuser, isServiceReady } = useUserService();

  const mainMenuModel = useMemo(
    () =>
      isServiceReady
        ? MENU_STRUCTURE.filter((x) => {
            return (
              x.mainMenu === VisibleE.always ||
              (isAuthenticated && x.mainMenu === VisibleE.if_logged_in) ||
              (isSuperuser && x.mainMenu === VisibleE.if_superuser)
            );
          })
        : [],
    [isAuthenticated, isServiceReady, isSuperuser]
  );

  const kachelMenuModel = useMemo(
    () =>
      isServiceReady
        ? MENU_STRUCTURE.filter((x) => {
            return (
              x.kachel === VisibleE.always ||
              (isAuthenticated && x.kachel === VisibleE.if_logged_in) ||
              (isSuperuser && x.kachel === VisibleE.if_superuser)
            );
          })
        : null,
    [isAuthenticated, isServiceReady, isSuperuser]
  );

  const ret = { mainMenuModel, kachelMenuModel };
  return ret;
}

export default useMenuService;
