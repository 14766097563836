import { ZoomInMapTwoTone } from "@mui/icons-material";
import React from "react";
import { useNavigate } from "react-router-dom";
import ISmartAction from "../components/buttons/ISmartAction";
import GeoSearchComponent from "../components/search/GeoSearchComponent";
import SmartActionBar from "./../components/buttons/SmartActionBar";
import { ICoordinates } from "./../components/search/GeoSearchComponent";
import { StyledContent } from "./../components/styles/styled.Content";
import { useTranslation } from "react-i18next";

function SearchLocationPage() {
  const { t } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [geoSelectObject, setGeoSelectObject] = React.useState<any>(null);
  function handleGeoSelect(gso: any) {
    setGeoSelectObject(gso);
  }
  const [coordinates, setCoordinates] = React.useState<ICoordinates | null>(
    null
  );
  function handleCoordinates(c: any) {
    setCoordinates(c);
  }
  const navigate = useNavigate();
  function _handleFly() {
    if (coordinates != null) {
      const newLoc = {
        latitude: coordinates.latitude,
        longitude: coordinates.longitude,
      };
      sessionStorage.setItem("flyToLocation", JSON.stringify(newLoc));
      navigate("/map");
    }
  }
  function isNavigatePossible() {
    if (coordinates != null) {
      return true;
    } else {
      return false;
    }
  }

  const actions: ISmartAction[] = [
    {
      text: t("show-on-the-map"),
      shortText: t("map"),
      onMouse: () => _handleFly(),
      tooltipPlacement: "bottom",
      children: <ZoomInMapTwoTone />,
      disabled: !isNavigatePossible(),
    },
  ];

  return (
    <StyledContent>
      <GeoSearchComponent
        onSelect={handleGeoSelect}
        onCoordinates={handleCoordinates}
      />
      <SmartActionBar actions={actions} showLabels={true} />
    </StyledContent>
  );
}

export default SearchLocationPage;
