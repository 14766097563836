import { IPlacelist } from "../stuff/AppModelTypes";
import { useCompletePlaces } from "./PlacesService";
import { useGetRelationsUserdataForPlacelist } from "./RelationsServices";
import { useUserService } from "./UserService";

export function useGetPlacesFromPlacelist(placelist: IPlacelist) {
  let places = placelist.Places;
  if (places == null) {
    places = [];
  }
  const ret = useCompletePlaces(places);
  return ret;
}

export function useGetUserRoleFromPlacelist(placelist: IPlacelist) {
  const { userDataId } = useUserService();
  const relations = useGetRelationsUserdataForPlacelist(placelist);
  let relation = null;
  if (userDataId != null && relations != null && relations.length > 0) {
    relation = relations.filter((rel: any) => {
      return (
        rel.userdata_id === userDataId || rel.userdata_id.Id === userDataId
      );
    });
  }
  if (relation != null && relation.length > 0) {
    return relation[0].Role;
  }
  return null;
}
