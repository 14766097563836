import { Skeleton, Stack } from "@mui/material";
import React, { useState } from "react";
import {
  BLACK_WATER_DISPOSAL,
  compareFeatures,
  FeatureConst,
  FEATURES_ALL,
  FRESH_WATER_SUPPLY,
  GRAY_WATER_DISPOSAL,
  SHOWERS,
  TOILETS,
} from "../api/FeaturesConstants";
import SmartAccordionLazy from "../components/accordion/SmartAccordionLazy";
import NearbyPlacesRadius from "../components/places/NearbyPlacesRadius";
import useTranslationService from "../services/TranslationService";

const DISTANCE_IN_KM = 25;

export function QuickPage() {
  const { t } = useTranslationService();
  const [here, setHere] = useState<any>(null);
  navigator.geolocation.getCurrentPosition(function (position) {
    const geo = position.coords;
    if (geo && here == null) {
      setHere({
        longitude: geo.longitude,
        latitude: geo.latitude,
      });
      // sessionStorage.setItem("flyToLocation", JSON.stringify(newLoc));
      // navigate("/map");
    }
  });

  const allFeatures = [...FEATURES_ALL].sort((a, b) => compareFeatures(a, b));

  return (
    <Stack>
      {_createPart("Ver- und Entsorgung", [
        GRAY_WATER_DISPOSAL,
        BLACK_WATER_DISPOSAL,
        FRESH_WATER_SUPPLY,
      ])}
      {_createPart("Entsorgung", [GRAY_WATER_DISPOSAL, BLACK_WATER_DISPOSAL])}
      {_createPart("Dusche und WC", [SHOWERS, TOILETS])}
      {allFeatures.map((fc) => {
        return _createPartForSingleFeature(fc);
      })}
    </Stack>
  );

  // -----------------------------

  function _createPartForSingleFeature(fConstant: FeatureConst) {
    return _createPart(t(fConstant.translationKey), [fConstant]);
  }
  function _createPart(title: string, fConstants: FeatureConst[]) {
    return (
      <SmartAccordionLazy
        header={title}
        childrenCallback={() => {
          return _createNearbyComponent(fConstants);
        }}
      />
    );
  }

  function _createNearbyComponent(fConstants: any[]) {
    if (here == null) {
      return <Skeleton />;
    }
    const features = fConstants.map((fc) => {
      return { domain: fc.key, checked: true };
    });
    return (
      <NearbyPlacesRadius
        defaultExpanded={true}
        longitude={here.longitude}
        latitude={here.latitude}
        distanceInKm={DISTANCE_IN_KM}
        additionalQueryProps={{
          features: features,
        }}
      />
    );
  }
}
