import { IPlacelist } from "../../stuff/AppModelTypes";
import { useGetPlacelistQuery } from "../../api/PlacesApiSlice";
import useAppService from "../../services/AppService";
import { usePlacelistsService } from "../../services/PlacelistsService";
import createCreatePlacelistAction from "./CreatePlacelistAction";
import createNavigateToPlacelistDetailsAction from "./NavigateToPlacelistDetailsAction";
import { concatAllArraysWithoutDuplicates } from "../../utils/ArrayUtils2";
// import createRenamePlacelistAction from "./RenamePlacelistAction";
// import AddUserToPlacelistAction from "./AddUserToPlacelistAction";
// import createDeletePlacelistAction from "./DeletePlacelistAction";
// import useTranslationService from "../../services/TranslationService";

function usePlacelistActions(placelistId?: string, placelist?: IPlacelist) {
  // const { t } = useTranslationService();
  const {
    createPlacelist,
    //  updatePlacelistName,
    //   deletePlacelist
  } = usePlacelistsService();
  const { navigate, pathname } = useAppService();
  const skip = placelistId == null;
  const { data: queryResult } = useGetPlacelistQuery(placelistId, { skip });
  let placelistObject = placelist;
  if (placelistObject == null) {
    if (queryResult) {
      if (queryResult.records && queryResult.records[0]) {
        placelistObject = queryResult.records[0];
      }
    }
  }
  if (placelistId == null) {
    placelistId = placelistObject?.Id;
  }
  // function renamePlacelist(newName: string) {
  //   if (placelistId != null) {
  //     updatePlacelistName(placelistId, newName);
  //   }
  // }
  const onePlacelistActions = [];
  const overPlacelistActions = [];

  if (placelistObject != null) {
    onePlacelistActions.push(
      createNavigateToPlacelistDetailsAction(
        placelistObject,
        navigate,
        pathname
      )
    );
    // const renameAction = createRenamePlacelistAction(
    //   placelistObject,
    //   renamePlacelist
    // );
    // onePlacelistActions.push(renameAction);
    // const addUserAction = AddUserToPlacelistAction(placelistObject);
    // onePlacelistActions.push(addUserAction);
    // const deleteAction = createDeletePlacelistAction(
    //   placelistObject,
    //   deletePlacelist,
    //   navigate,
    //   t
    // );
    // onePlacelistActions.push(deleteAction);
  }
  const createAction = createCreatePlacelistAction(createPlacelist);
  overPlacelistActions.push(createAction);

  const allPlacelistActions = concatAllArraysWithoutDuplicates([
    onePlacelistActions,
    overPlacelistActions,
  ]);

  const ret = {
    placelistObject,
    onePlacelistActions,
    overPlacelistActions,
    allPlacelistActions,
  };
  return ret;
}

export default usePlacelistActions;
