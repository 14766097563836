import { Autocomplete, TextField, Typography } from "@mui/material";
import * as locales from "@mui/material/locale";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

export const LanguageSelect = (props: any) => {
  const { t, i18n } = useTranslation();
  const currLang = i18n.language;
  const [locale, setLocale] = useState(currLang);

  const ls = i18n.languages;

  return (
    <>
      <Autocomplete
        options={Object.keys(locales)}
        getOptionLabel={(key) =>
          `${key.substring(0, 2)}-${key.substring(2, 4)}`
        }
        style={{ width: 300 }}
        value={locale}
        disableClearable
        onChange={(event, newValue) => {
          setLocale(newValue);
          i18n.changeLanguage(extractLanguageCode(newValue));
        }}
        renderInput={(params) => (
          <TextField {...params} label={t("language")} fullWidth />
        )}
      />
      <Typography>{ls}</Typography>
    </>
  );
};

function extractLanguageCode(text: string) {
  return text.substring(0, 2);
}
