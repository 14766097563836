import {
  useAddPlaceToPlacelistMutation,
  useCreatePlacelistMutation,
  useDeletePlacelistMutation,
  useGetUserdataForUserIdQuery,
  useUpdatePlacelistNameMutation,
} from "../api/PlacesApiSlice.js";
import { IPlacelist } from "../stuff/AppModelTypes.js";
import { useUserService } from "./UserService";

export function usePlacelistsService(
  givenUserId?: string,
  givenPlaceId?: string
) {
  const [_updatePlacelistName] = useUpdatePlacelistNameMutation();
  // const [_updatePlacelistPlaces] = useUpdatePlacelistPlacesMutation();
  const [_addPlaceToPlacelist] = useAddPlaceToPlacelistMutation();
  const [_createPlacelist] = useCreatePlacelistMutation();
  const [_deletePlacelist] = useDeletePlacelistMutation();
  let { userId } = useUserService();
  if (givenUserId != null) {
    userId = givenUserId;
  }
  // const { data: placelistsOfUserQueryResult } = useGetPlacelistsForUserQuery({
  //   userId: userId,
  //   placeId: givenPlaceId,
  // });
  const { data: userdataForUserIdQueryResult } =
    useGetUserdataForUserIdQuery(userId);

  // const { data: relationsUserdataToPlacelistResult } =
  //   useGetRelationsUserdataToPlacelistQuery({ placelists_id :placelist.Id});

  let ret = {
    placelists: [] as IPlacelist[],
    getPlacelist,
    updatePlacelistName,
    // updatePlacelistPlaces,
    addPlaceToPlacelist,
    createPlacelist,
    deletePlacelist,
    // isPlacelistViewer,
    // getViewerPlacelists,
    isPlacelistEditor,
    // getEditorPlacelists,
  };

  // if (placelistsOfUserQueryResult != null) {
  //   const records = placelistsOfUserQueryResult.records;
  //   if (records != null && records.length > 0) {
  //     ret.placelists = _getPlacelistsFromRecords(records);
  //   }
  // }
  if (userdataForUserIdQueryResult != null) {
    const records = userdataForUserIdQueryResult.records;
    if (records != null && records.length > 0) {
      const userData = records[0];
      ret.placelists = userData.Placelists;
    }
  }
  return ret;

  // Helper Functions
  function updatePlacelistName(placelistId: string, newName: string) {
    _updatePlacelistName({ placelistId, newName });
  }
  // function updatePlacelistPlaces(placelistId: string, placeIds: number[]) {
  //   const places = JSON.stringify(placeIds);
  //   _updatePlacelistPlaces({ placelistId, places });
  // }
  function addPlaceToPlacelist(placelistId: string, placeId: string) {
    _addPlaceToPlacelist({
      places_id: +placeId,
      placelists_id: +placelistId,
    });
  }
  function createPlacelist(name: string) {
    if (name == null) {
      throw new Error("no name provided");
    }
    const initialUsers = [{ User: userId, Role: "editor" }];
    const newEntry = { Name: name, Users: JSON.stringify(initialUsers) };
    _createPlacelist(newEntry);
  }
  function deletePlacelist(placelistId: string) {
    if (placelistId == null) {
      throw new Error("no id provided");
    }
    _deletePlacelist(placelistId);
  }
  function getPlacelist(placelistId: string) {
    const allPlacelists = ret.placelists;
    for (let index = 0; index < allPlacelists.length; index++) {
      const pl = allPlacelists[index];
      if (pl.Id === placelistId) {
        return pl;
      }
    }
  }
  function isPlacelistEditor(placelistId: string) {
    return false; //todo
    // return _getPlacelistUserRole(placelistId) === "editor";
  }
  // function getEditorPlacelists() {
  //   const r = [];
  //   for (let i = 0; i < ret.placelists.length; i++) {
  //     const pl = ret.placelists[i];
  //     if (isPlacelistEditor(pl.Id)) {
  //       r.push(pl);
  //     }
  //   }
  //   return r;
  // }
  // function isPlacelistViewer(placelistId: string) {
  //   return _getPlacelistUserRole(placelistId) === "viewer";
  // }
  // function getViewerPlacelists() {
  //   const r = [];
  //   for (let i = 0; i < ret.placelists.length; i++) {
  //     const pl = ret.placelists[i];
  //     if (isPlacelistViewer(pl.Id)) {
  //       r.push(pl);
  //     }
  //   }
  //   return r;
  // }
  // function _getPlacelistUserRole(placelistId: string) {
  //   const pl = getPlacelist(placelistId);
  //   if (pl) {
  //     const users = pl.Users;
  //     for (let index = 0; index < users.length; index++) {
  //       const user = users[index];
  //       return user.Role;
  //     }
  //   }
  //   return null;
  // }
}

// Helper Functions
// function _getPlacelistsFromRecords(records: any[]): IPlacelist[] {
//   let ret = [];
//   for (let i = 0; i < records.length; i++) {
//     const r = records[i];
//     const pl = _getPlacelistFromRecord(r);
//     if (pl != null) {
//       ret.push(pl);
//     }
//   }
//   return ret;
// }

// function _getPlacelistFromRecord(record: any): IPlacelist | null {
//   if (record == null) {
//     return null;
//   }
//   let ret: IPlacelist = {
//     Id: "" + record.Id,
//     Name: record.Name,
//     Places: _getPlacesFromRecord(record),
//     Data: JSON.parse(record.Data),
//     Users: _getPlacelistUsersFromUserJson(record.Users),
//   };
//   return ret;
// }

// function _getPlacesFromRecord(record: any): IPlace[] {
//   return record.Places;
//   // const ret: IPlace[] = [];
//   // const placesParsed = JSON.parse(record.Places);
//   // if (placesParsed != null) {
//   //   for (let i = 0; i < placesParsed.length; i++) {
//   //     const p = placesParsed[i];
//   //     const place: IPlace = { Id: "" + p };
//   //     ret.push(place);
//   //   }
//   // }
//   // return ret;
// }

// function _getPlacelistUsersFromUserJson(Users: any): IPlacelistUser[] {
//   let ret: IPlacelistUser[] = [];
//   const uList = JSON.parse(Users);
//   if (uList != null) {
//     for (let i = 0; i < uList.length; i++) {
//       const u = uList[i];
//       const uObject = {
//         UserId: u.User,
//         Role: u.Role,
//       };
//       ret.push(uObject);
//     }
//   }
//   return ret;
// }

export function findPlacelistInArray(
  placelists: IPlacelist[],
  relation: any
): any {
  for (let index = 0; index < placelists.length; index++) {
    const pl = placelists[index];
    if (
      pl.Id === relation.placelists_id ||
      pl.Id === relation.placelists_id.Id
    ) {
      return pl;
    }
  }
  return null;
}
export function findRelationInArray(
  relations: any[],
  placelist: IPlacelist
): any {
  if (relations != null) {
    for (let index = 0; index < relations.length; index++) {
      const rel = relations[index];
      if (
        placelist.Id === rel.placelists_id ||
        placelist.Id === rel.placelists_id.Id
      ) {
        return rel;
      }
    }
  }
  return null;
}
