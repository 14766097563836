import React from "react";
import { AbstractScrapeLinksSincePage } from "./AbstractScrapeLinksSincePage";

export function NewScrapeLinksSincePage() {
  return (
    <AbstractScrapeLinksSincePage
      titleKey={"new-scrape-links-from-the-last-time"}
      queryKey={"fromCreatedAt"}
    />
  );
}
