export const StandardTheme = {
  dimensions: {
    header: {
      height: "150px",
      padding: "0 20px",
    },
    container: {
      height: "500px",
      padding: "0 0",
    },
    content: {
      // height: "100vh",
      padding: "10px 20px",
    },
    body: {
      padding: "10px 20px",
    },
    footer: {
      height: "80px",
      padding: "0px 20px 0px 20px",
    },
  },

  fonts: {
    header: {
      // fontWeight: "bold",
    },
    container: {},
    content: {},
    body: {},
    footer: {},
  },

  colors: {
    header: {
      color: "lightgray",
      bg: "black",
    },
    container: {
      color: "green",
      bg: "lightgray",
    },
    content: {
      color: "black",
      bg: "lightgray",
    },
    button: {
      color: "green",
      bg: "lightgray",
    },
    body: {
      color: "darkred",
      bg: "darkgray",
    },
    footer: {
      color: "darkgray",
      bg: "black",
    },
  },

  components: {
    MuiStack: {
      defaultProps: {
        useFlexGap: true,
      },
    },
  },

  others: {
    header: {},
    container: {},
    content: {},
    body: {},
    footer: {},
  },
};
