import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { placesApi } from "./api/PlacesApiSlice";

const Store = configureStore({
  reducer: {
    [placesApi.reducerPath]: placesApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(placesApi.middleware),
});

setupListeners(Store.dispatch);

export default Store;