import {
    INITIAL_COUNTRY_OPTIONS,
    INITIAL_FEATURES_OPTIONS,
    INITIAL_PROVIDER_OPTIONS,
} from "../components/places/PlacesConstants";
import { isArrayOfStrings, isJSONString } from "../utils/Utils";

const STORAGE_KEY_PROVIDERS = "providers";
const STORAGE_KEY_FEATURES = "features";
const STORAGE_KEY_COUNTRIES = "countries";

export function recallCountries() {
  const storedCountries = localStorage.getItem(STORAGE_KEY_COUNTRIES);
  if (storedCountries != null && isJSONString(storedCountries)) {
    return _cleanupStoredCountries(JSON.parse(storedCountries));
  } else {
    return INITIAL_COUNTRY_OPTIONS;
  }
}

export function recallFeatures() {
  const storedFeatures = localStorage.getItem(STORAGE_KEY_FEATURES);
  if (storedFeatures != null && isJSONString(storedFeatures)) {
    return JSON.parse(storedFeatures);
  } else {
    return INITIAL_FEATURES_OPTIONS;
  }
}

export function recallProviders() {
  const providerFeatures = localStorage.getItem(STORAGE_KEY_PROVIDERS);
  if (providerFeatures != null && isJSONString(providerFeatures)) {
    return JSON.parse(providerFeatures);
  } else {
    return INITIAL_PROVIDER_OPTIONS;
  }
}

export function storeProviders(providers: any) {
    storeObject(STORAGE_KEY_PROVIDERS, providers);
}
export function storeFeatures(features: any) {
    storeObject(STORAGE_KEY_FEATURES, features);
}
export function storeCountries(countries: any) {
    storeObject(STORAGE_KEY_COUNTRIES, countries);
}

function storeObject(key:string, value: any) {
    const stringified = JSON.stringify(value);
    localStorage.setItem(key, stringified);
}

//------------------

function _cleanupStoredCountries(countries: any[]) {
    if (!countries) {
      return [];
    }
    if (isArrayOfStrings(countries)) {
      return countries;
    }
    return [];
  }
  