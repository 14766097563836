import React from "react";
import GenericList from "../place/parts/GenericList";
import PlaceSmallFromId from "./../place/PlaceSmallFromId";

type IProps = {
  placeIds: string[];
  fromLatiude?: number;
  fromLongitude?: number;
  infoVisibility?: "expanded" | "collapsed" | "hidden";
};

function PlacesListFromIds(props: IProps) {
  function createPlaceComponent(pid: any) {
    return (
      <PlaceSmallFromId
        key={"PlaceSmallFromId_" + pid}
        placeId={pid.toString()}
        enableFlyTo={true}
        fromLatiude={props.fromLatiude}
        fromLongitude={props.fromLongitude}
        infoVisibility={props.infoVisibility}
      />
    );
  }

  return (
    <GenericList
      elements={props.placeIds}
      renderProps={props}
      renderComponent={createPlaceComponent}
    />
  );
}

export default PlacesListFromIds;
