import { ApiProvider } from '@reduxjs/toolkit/dist/query/react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { placesApi } from './api/PlacesApiSlice';
import { AppBrowserRouter } from './stuff/AppBrowserRouter';
import { Auth0Provider } from '@auth0/auth0-react';
import Store from './Store';
import "./i18n";


const root = ReactDOM.createRoot(document.getElementById('root'));

const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
const authParams = { redirect_uri: window.location.origin };

root.render(
  <React.StrictMode>
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={authParams}>
      <Provider store={Store}>
        <ApiProvider api={placesApi}>
          <RouterProvider router={AppBrowserRouter} >
          </RouterProvider>
        </ApiProvider>
      </Provider>
    </Auth0Provider>
  </React.StrictMode>
);
