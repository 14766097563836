import { Checkbox, FormControlLabel, Stack } from "@mui/material";
import React from "react";
import { useState } from "react";

type IProps = {
  entries: any[];
  onChange: any;
};

function MultiCheckboxComponent({ entries, onChange }: IProps) {
  let ret = null;
  if (entries && entries.length > 0) {
    ret = (
      <>
        <Checkboxes allEntries={entries} onCheckboxChange={onChange} />
      </>
    );
  }
  return ret;
}

function Checkboxes({
  allEntries,
  onCheckboxChange,
}: {
  allEntries: any[];
  onCheckboxChange: any;
}) {
  const entriesModel = _createEntriesModel(allEntries);
  function handleCheckInOneCheckboxChanged(
    entryName: string,
    newCheckState: boolean
  ) {
    _updateEntriesModel(entriesModel, entryName, newCheckState);
    onCheckboxChange(entriesModel);
  }
  const ret = (
    <>
      {entriesModel &&
        entriesModel.map((p) => {
          let label = p.label;
          if (p.icon != null) {
            label = (
              <Stack
                direction="row"
                spacing={1}
                sx={{
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                {React.createElement(p.icon)}
                {label}
              </Stack>
            );
          }
          // if (p.icon != null) {
          //   label = { p.icon};
          //   // (
          //     // <>
          //       // { p.icon}
          //       {/* {label} */}
          //     // </>
          //   // );
          // }
          return (
            <OneCheckbox
              key={p.id}
              id={p.id}
              label={label}
              checked={p.checked}
              onCheckChanged={handleCheckInOneCheckboxChanged}
            ></OneCheckbox>
          );
        })}
    </>
  );
  return ret;
}

function OneCheckbox({
  id,
  label,
  checked,
  onCheckChanged,
}: {
  id: string;
  label: any;
  checked: boolean;
  onCheckChanged: any;
}): React.JSX.Element {
  const [checkedNow, setCheckedNow] = useState(checked);
  function changed(e: any) {
    const newState = !checkedNow;
    setCheckedNow(newState);
    onCheckChanged(id, newState);
  }
  // const labelWithIcon = (
  //   <>
  //     <PodcastsTwoTone />
  //     {label}
  //   </>
  // );
  return (
    <FormControlLabel
      key={id}
      control={<Checkbox checked={checkedNow} onChange={changed} />}
      label={label}
    />
  );
}

function _createEntriesModel(allEntries: any[]) {
  const ret = [...allEntries];
  return ret;
}
function _updateEntriesModel(
  entriesModel: any,
  entryName: string,
  newCheckState: boolean
) {
  for (let i = 0; i < entriesModel.length; i++) {
    const p = entriesModel[i];
    if (p.id === entryName) {
      p.checked = newCheckState;
    }
  }
}

export default MultiCheckboxComponent;
