import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { equalObject } from "../../utils/Utils";
import { IForm } from "../forms/IForm";
import SmartTooltip from "../others/SmartTooltip";
import { IDialogProps } from "./IDialogProps";
import useTranslationService from "../../services/TranslationService";

interface IProps {
  dialogProps: IDialogProps;
  open: boolean;
}

function SmartDialog({ dialogProps, open }: IProps): JSX.Element | null {
  const { tCapitalize } = useTranslationService();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [data, setData] = useState<any>(null);
  const [errorText, setErrorText] = useState<string | null>(null);
  // const [isValid, setValid] = useState<boolean | null>();
  const [dialogContent, setDialogContent] = useState<any | null>();

  function handleOk(): void {
    // console.log("-> SmartDialog.handleOk() ");
    if (dialogProps.onOk != null) {
      dialogProps.onOk(data);
    }
  }
  function handleClose() {
    // console.log("-> SmartDialog.handleClose()");
    if (dialogProps.onClose != null) {
      dialogProps.onClose();
    }
  }

  useEffect(() => {
    if (!open) {
      return;
    }
    if (dialogContent != null) {
      return;
    }
    function handleData(_data: any) {
      // console.log("-> SmartDialog.handleData()");
      if (!equalObject(_data, data)) {
        setData(_data);
      }
    }
    function handleError(et: string | null) {
      // console.log("-> SmartDialog.handleError()");
      setErrorText(et);
    }
    const dialogContentProps: IForm = {
      data: null,
      onDataChange: handleData,
      onError: handleError,
    };
    const { content } = dialogProps.onCreateContent(dialogContentProps);
    setDialogContent(content);
  }, [data, dialogContent, dialogProps, errorText, open]);

  if (!open) {
    return null;
  }

  const closeHandler = dialogProps.onClose;
  const okHandler = dialogProps.onOk;
  let closeText, okText;
  if (okHandler == null) {
    closeText = tCapitalize("close");
  } else {
    okText = tCapitalize("ok");
    if (closeHandler == null) {
      okText = tCapitalize("ok");
    } else {
      closeText = tCapitalize("cancel");
    }
  }
  const dialogActions = (
    <DialogActions>
      {closeText != null && <Button onClick={handleClose}>{closeText}</Button>}
      {okText != null && (
        <SmartTooltip title={"Okay"} color="red">
          <Button onClick={handleOk} disabled={errorText != null}>
            {okText}
          </Button>
        </SmartTooltip>
      )}
    </DialogActions>
  );
  const ret = (
    <div>
      <Dialog
        onClose={dialogProps.onClose}
        open={open}
        scroll="paper"
        fullScreen={fullScreen}
      >
        <DialogTitle>{dialogProps.title}</DialogTitle>
        <DialogContent dividers>
          {/* <SmartAttribute label="Name" value={p.oldValue} /> */}
          {dialogContent}
          {errorText != null && (
            <Typography color="red">{errorText}</Typography>
          )}
        </DialogContent>
        {dialogActions}
      </Dialog>
    </div>
  );
  return ret;
}

export default SmartDialog;
