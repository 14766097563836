import React from "react";
import { useParams } from "react-router-dom";
import PlaceLarge from "../components/place/PlaceLarge";
import usePlaceService, { SKIP_PLACE } from "../services/PlaceService";
import { StyledContent } from "./../components/styles/styled.Content";
import { COLOR_BACKGROUND_DELETED } from "../stuff/StyleConstants";

export function PlaceDetailsPage() {
  let sx = null;
  const routerParams = useParams();
  let placeIdString = "";
  let p = null;
  if (routerParams && routerParams.id) {
    placeIdString = routerParams.id;
  }
  let { placeObject } = usePlaceService(placeIdString);
  if (placeObject == null) {
    placeObject = SKIP_PLACE;
  }
  // const placeDeleted = useCheckIfPlaceIsDeleted(placeObject);
  const placeDeleted =
    placeObject.ScrapeLinks != null && placeObject.ScrapeLinks.length < 1;
  if (placeDeleted) {
    sx = { background: COLOR_BACKGROUND_DELETED };
  }
  if (placeObject != null) {
    p = (
      <PlaceLarge
        placeObject={placeObject}
        showTitle={false}
        showInfo={false}
      />
    );
  }
  return <StyledContent sx={sx}>{p}</StyledContent>;
}
