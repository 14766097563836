import { Icon, LatLng, LatLngBounds } from "leaflet";
import { IPlace } from "../../stuff/AppModelTypes";
import { getCountryCoordinatesFromCountryCode } from "../../utils/AppUtils";
import { IBound, arrayToBounds, uniteBounds } from "./MapUtils";

export const WORLD_MAX_BOUNDS = fromBoundsToMapBounds({
  south: -90,
  east: 180,
  north: 90,
  west: -180,
});
export function createCrossIcon() {
  return createIcon("/images/crosshair_white_fill.svg", 40);
}

export function createIcon(url: string, size?: number) {
  if (size == null) {
    size = 40;
  }
  const ret = new Icon({
    iconUrl: url,
    iconSize: [size, size],
    iconAnchor: [size / 2, size / 2],
  });
  return ret;
}

export function createHighlightIcon(
  iconUrl: string,
  highlight?: boolean,
  highlightFactor?: number
) {
  if (highlight == null) {
    highlight = false;
  }
  if (highlightFactor == null) {
    highlightFactor = 1;
  }
  return highlight
    ? new Icon({
        iconUrl: iconUrl,
        iconSize: [38 * highlightFactor, 95 * highlightFactor],
        iconAnchor: [19 * highlightFactor, 65 * highlightFactor],
        popupAnchor: [0, -40 * highlightFactor],
      })
    : new Icon({
        iconUrl: iconUrl,
        iconSize: [38, 95],
        iconAnchor: [19, 65],
        popupAnchor: [0, -40 * highlightFactor],
      });
}

export function fromBoundsToMapBounds(bounds: IBound) {
  const corner1 = new LatLng(bounds.north, bounds.east);
  const corner2 = new LatLng(bounds.south, bounds.west);
  const ret = new LatLngBounds(corner1, corner2);
  return ret;
}

export function isPlaceInBounds(
  place: { Latitude: any; Longitude: any },
  bounds: { west: any; east: any; north: any; south: any }
) {
  let ret = false;
  const { west, east, north, south } = bounds;
  const latitude = place.Latitude;
  const longitude = place.Longitude;
  if (
    south <= latitude &&
    latitude <= north &&
    west <= longitude &&
    longitude <= east
  ) {
    ret = true;
  }
  return ret;
}

export function findCountryBoundsForPlace(place: IPlace): IBound {
  if (place.Land != null) {
    const countryFromPlace = place.Land;
    const countryId = countryFromPlace;
    if (countryId != null) {
      const coordsArray = getCountryCoordinatesFromCountryCode(countryId);
      if (coordsArray != null) {
        return arrayToBounds(coordsArray);
      }
    }
  }
  return arrayToBounds([-5.0, 35.5, 15.56, 60.15]);
}
export function findCountryBoundsForPlaces(places: IPlace[]): IBound {
  let ret;
  for (let index = 0; index < places.length; index++) {
    const onePlace = places[index];
    const bounds4OnePlace = findCountryBoundsForPlace(onePlace);
    if (ret == null) {
      ret = bounds4OnePlace;
    } else {
      ret = uniteBounds(ret, bounds4OnePlace);
    }
  }
  if (ret == null) {
    ret = arrayToBounds([-5.0, 35.5, 15.56, 60.15]);
  }
  return ret;
}
