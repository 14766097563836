import React from "react";
import { POWER_CONNECTION } from "../../../api/FeaturesConstants";
import { FeatureChip, FeatureChipSubtypeProps } from "./FeatureChip";

export function PowerConnectionChip({
  feature,
  available,
}: FeatureChipSubtypeProps) {
  return (
    <FeatureChip
      feature={feature}
      featureConst={POWER_CONNECTION}
      available={available}
    />
  );
}
