export enum PlacelistsAccess {
  Default = "default",
  Public = "public",
}

export enum UserdataPlacelistsRole {
  Admin = "admin", // edit placelist; add and remove places; add and remove users
  Editor = "editor", // edit placelist; add and remove places
  Viewer = "viewer", // just see the placelist; cannot edit anything
}
