import { PureComponent, useEffect, useState } from 'react';
import { Area, ComposedChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { CustomRechartsTooltip } from './RechartsUtils.jsx';

function StatisticsComponent(props) {
  const [statisticsReduced, setStatisticsReduced] = useState([]);

  class CustomizedAxisTick extends PureComponent {
    render() {
      const { x, y, payload } = this.props;

      return (
        <g transform={`translate(${x},${y})`}>
          <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-60)">
            {new Date(payload.value).toLocaleDateString()}
          </text>
        </g>
      );
    }
  }

  useEffect(() => {
    let newStat = props.statistics
    const length = props.statistics.length;
    if (props.size < length) {
      newStat = props.statistics.slice(length - props.size);
    }
    setStatisticsReduced(newStat);
  }, [props.statistics, props.size]);

  const areas = <>
    {
      props.set.map((s) => {
        return <Area key={s.name} type="monotone" dataKey={s.name} stroke={s.color} fill={s.color} fillOpacity={.2} />
      })};
  </>;

  return (
    <div style={{ width: '100%', height: 800 }}>
      <ResponsiveContainer >
        <ComposedChart
          width={500}
          height={400}
          data={statisticsReduced}
          margin={{
            top: 10,
            right: 10,
            left: 0,
            bottom: 0,
          }}
        >
          {/* <CartesianGrid strokeDasharray="3 3" /> */}
          <XAxis dataKey="dateToNumber" tick={<CustomizedAxisTick />} height={100} type="number" scale="time" domain={['dataMin', 'dataMax']}>
          </XAxis>
          <YAxis type="number" domain={['dataMin', 'auto']} />
          <Tooltip content={<CustomRechartsTooltip />} />
          <Legend verticalAlign="middle" layout='vertical' height={80} />
          {areas}
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
}

export default StatisticsComponent;
