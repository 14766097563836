import React from "react";
import { TUMBLE_DRYER } from "../../../api/FeaturesConstants";
import { FeatureChip, FeatureChipSubtypeProps } from "./FeatureChip";

export function TumbleDryerChip({
  feature,
  available,
}: FeatureChipSubtypeProps) {
  return (
    <FeatureChip
      feature={feature}
      featureConst={TUMBLE_DRYER}
      available={available}
    />
  );
}
