import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Tooltip,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import useMenuService from "../../services/MenuService";
import { IMenuEntry } from "../../stuff/AppConstants";
import { DRAWER_WIDTH } from "../../stuff/StyleConstants";

// implementation from here: https://www.geeksforgeeks.org/react-mui-drawer-navigation/

interface IProps {
  isOpen: boolean;
  toggleDrawer: any;
}

function MainDrawer({ isOpen, toggleDrawer }: IProps): JSX.Element | null {
  const { t, i18n } = useTranslation();
  const { mainMenuModel } = useMenuService();

  const drawerContent = (
    <Box
      // sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer} //{toggleDrawer(false)}
      onKeyDown={toggleDrawer} //{toggleDrawer(false)}
    >
      <List>
        {mainMenuModel.map((x) => {
          return _createListItem(x);
        })}
      </List>
    </Box>
  );

  function _createListItem(x: IMenuEntry) {
    const name =
      x.nameKey != null ? i18n.format(t(x.nameKey), "capitalize") : "";
    const tooltip =
      x.tooltipKey != null ? i18n.format(t(x.tooltipKey), "capitalize") : "";
    return (
      <Tooltip
        key={x.link}
        title={tooltip}
        // enterDelay={700}
        placement={"right"}
      >
        <ListItem key={x.link} disablePadding>
          <ListItemButton component="a" href={"/" + x.link}>
            <ListItemText primary={name} />
          </ListItemButton>
        </ListItem>
      </Tooltip>
    );
  }

  const ret = (
    <Box
      component="nav"
      sx={{
        width: { sm: DRAWER_WIDTH },
        flexShrink: { sm: 0 },
      }}
    >
      <Drawer
        variant="temporary"
        open={isOpen}
        onClose={toggleDrawer}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: DRAWER_WIDTH,
          },
        }}
      >
        {drawerContent}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: DRAWER_WIDTH,
          },
        }}
        open
      >
        {drawerContent}
      </Drawer>
    </Box>
  );
  return ret;
}

export default MainDrawer;
