import React from "react";
import useTranslationService from "../../services/TranslationService";
import { IPlace } from "../../stuff/AppModelTypes";
import { isNullOrUndefined } from "../../utils/Utils";
import SmartAccordionLazy from "../accordion/SmartAccordionLazy";
import NearbyPlacesRadius from "./NearbyPlacesRadius";

const RADIUS_LIST = [0.5, 1, 3, 5, 10, 15];

interface IProps {
  place: IPlace;
  defaultExpanded?: boolean;
}

function NearbyPlacesRadiusComponent({
  place,
  defaultExpanded = false,
}: IProps): JSX.Element | null {
  const { t } = useTranslationService();

  function createNearbyContent(): JSX.Element | JSX.Element[] {
    let ret: JSX.Element | JSX.Element[] = <></>;
    if (!isNullOrUndefined(place)) {
      const lat = place.Latitude;
      const lon = place.Longitude;
      if (lat != null && lon != null) {
        const excludeIds = [place.Id + ""];
        ret = RADIUS_LIST.map((r) => {
          return (
            <NearbyPlacesRadius
              key={"radius_" + r}
              distanceInKm={r}
              latitude={lat}
              longitude={lon}
              excludePlaceIds={excludeIds}
            />
          );
        });
      }
    }
    return ret;
  }

  const testtext = t("places-nearby");
  return (
    <SmartAccordionLazy
      key={"SmartAccordionLazy_" + place.Id}
      header={testtext}
      defaultExpanded={defaultExpanded}
      childrenCallback={createNearbyContent}
      disabled={RADIUS_LIST.length < 1}
    />
  );
}

export default NearbyPlacesRadiusComponent;
