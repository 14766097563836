import {
  PendingOutlined,
  SentimentVeryDissatisfied,
  SentimentVerySatisfied,
} from "@mui/icons-material";
import { Badge, IconButton, Menu, MenuItem } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import SmartTooltip from "../others/SmartTooltip";
import { useUserService } from "../../services/UserService";
import useTranslationService from "../../services/TranslationService";

function AuthenticationComponent() {
  const { t, tCapitalize } = useTranslationService();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const {
    loginWithRedirect,
    user,
    logout,
    isAuthenticated,
    isLoading,
    isSuperuser,
  } = useUserService();
  const navigate = useNavigate();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  let tooltipText = t("log-in");
  if (isLoading) {
    tooltipText = t("loading");
  } else {
    if (isAuthenticated) {
      if (user != null && user.nickname != null) {
        tooltipText = t("logged-in-as-user", { user: user.nickname });
      } else {
        tooltipText = t("registered");
      }
    }
  }
  if (isSuperuser) {
    tooltipText += " (Superuser)";
  }

  let iconForButton = null;
  if (isLoading) {
    iconForButton = <PendingOutlined fontSize="inherit" />;
  } else {
    if (isAuthenticated) {
      iconForButton = <SentimentVerySatisfied fontSize="inherit" />;
    } else {
      iconForButton = <SentimentVeryDissatisfied fontSize="inherit" />;
    }
  }

  //loginWithRedirect({ redirectUri: ${window.location.origin}/home, appState: {returnTo: href } });
  const appState = {
    screen_hint: tCapitalize("signup"),
    appState: {
      returnTo: window.location.pathname, //href
    },
  };

  let ret = (
    <>
      <SmartTooltip title={tooltipText}>
        <IconButton
          color="inherit"
          aria-label="menu"
          size="large"
          onClick={handleClick}
        >
          <Badge badgeContent="SU" invisible={!isSuperuser}>
            {iconForButton}
          </Badge>
        </IconButton>
      </SmartTooltip>
      {isLoading ? null : (
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {isAuthenticated ? null : (
            <MenuItem
              onClick={() => {
                loginWithRedirect(appState);
              }}
            >
              {tCapitalize("register-log-in")}{" "}
            </MenuItem>
          )}
          {isAuthenticated ? (
            <MenuItem
              onClick={() => {
                navigate("myaccount");
                handleClose();
              }}
            >
              {tCapitalize("my-account")}{" "}
            </MenuItem>
          ) : null}
          {isAuthenticated ? (
            <MenuItem
              onClick={() => {
                navigate("favorites");
                handleClose();
              }}
            >
              {tCapitalize("my-favorites")}{" "}
            </MenuItem>
          ) : null}
          {isAuthenticated ? (
            <MenuItem
              onClick={() => {
                navigate("myplacelists");
                handleClose();
              }}
            >
              {tCapitalize("my-place-lists")}{" "}
            </MenuItem>
          ) : null}
          {isAuthenticated ? (
            <MenuItem
              onClick={() => {
                logout();
              }}
            >
              {tCapitalize("log-out")}{" "}
            </MenuItem>
          ) : null}
        </Menu>
      )}
    </>
  );
  return ret;
}

export default AuthenticationComponent;
