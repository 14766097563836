import {
  useGetPlaceJoinedQuery,
  useGetRelationPlacelistsToPlacesForIdQuery,
  useGetRelationsPlacelistsToPlacesForPlaceIdQuery,
  useGetRelationsPlacelistsToPlacesForPlacelistIdQuery,
  useGetRelationsUserdataToPlacelistQuery,
  useGetRelationsUserdataToPlaceQuery,
  useGetRelationUserDataToPlacesForIdQuery,
  useGetRelationUserDataToPlacelistsForIdQuery,
} from "../api/PlacesApiSlice.js";
import {
  IPlace,
  IPlacelist,
  IRelationsPlacelistsPlaces,
} from "../stuff/AppModelTypes.js";

export function useGetUsersForPlacelist(placelist: IPlacelist) {
  const result = useGetRelationsUserdataToPlacelistQuery({
    placelists_id: placelist.Id,
  });
  return result;
}

export function useGetPlacelistsForPlace(place: IPlace) {
  let ret = place.Placelists;
  const skip = ret != null;
  const result = useGetPlaceJoinedQuery(place.Id, { skip: skip });
  if (ret != null) {
    return ret;
  } else {
    if (
      result != null &&
      result.data != null &&
      result.data.records != null &&
      result.data.records.length > 0
    ) {
      return result.data.records[0].Placelists;
    }
  }
  return [];
}
export function useGetRelationsPlace2Placelists(place: IPlace) {
  let ret = place.placelists_places;
  function checkAllRelationsForPlacelists(
    relations: IRelationsPlacelistsPlaces[]
  ) {
    for (let index = 0; index < relations.length; index++) {
      const rel = relations[index];
      const pl_id = rel.placelists_id;
      if (pl_id == null || typeof (pl_id + "") != "string") {
        return false;
      }
    }
    return true;
  }
  const skip = ret != null && checkAllRelationsForPlacelists(ret);
  const result = useGetRelationsPlacelistsToPlacesForPlaceIdQuery(place.Id, {
    skip: skip,
  });
  if (skip) {
    return ret;
  } else {
    if (
      result != null &&
      result.data != null &&
      result.data.records != null &&
      result.data.records.length > 0
    ) {
      return result.data.records;
    }
  }
  return [];
}
export function useGetRelationsPlacelist2Places(placelist: IPlacelist) {
  let ret = placelist.placelists_places;
  function checkAllRelationsForPlaces(relations: IRelationsPlacelistsPlaces[]) {
    for (let index = 0; index < relations.length; index++) {
      const rel = relations[index];
      const p_id = rel.places_id;
      if (p_id == null || typeof (p_id + "") == "string") {
        return false;
      }
    }
    return true;
  }
  const skip = ret != null && checkAllRelationsForPlaces(ret);
  const result = useGetRelationsPlacelistsToPlacesForPlacelistIdQuery(
    placelist.Id,
    {
      skip: skip,
    }
  );
  if (skip) {
    return ret;
  } else {
    if (
      result != null &&
      result.data != null &&
      result.data.records != null &&
      result.data.records.length > 0
    ) {
      return result.data.records;
    }
  }
  return [];
}

export function useGetRelationsUserdataForPlace(place: IPlace) {
  let ret = place.userdata_places;
  const skip = ret != null;
  const result = useGetRelationsUserdataToPlaceQuery(place.Id, { skip: skip });
  if (ret != null) {
    return ret;
  } else {
    if (
      result != null &&
      result.data != null &&
      result.data.records != null &&
      result.data.records.length > 0
    ) {
      return result.data.records[0].userdata_places;
    }
  }
  return [];
}
export function useGetRelationsUserdataForPlacelist(placelist: IPlacelist) {
  let ret = placelist.userdata_placelists;
  const skip = ret != null;
  const result = useGetRelationsUserdataToPlacelistQuery(
    { placelists_id: placelist.Id },
    {
      skip: skip,
    }
  );
  if (ret != null) {
    return ret;
  } else {
    if (result != null && result.data != null && result.data.records != null) {
      return result.data.records;
    }
  }
  return [];
}

export function useCompleteRelationPlaceListPlaces(relation: any) {
  let skip = checkIfAllObjectsComplete([
    relation.placelists_id,
    relation.places_id,
  ]);
  const answer = useGetRelationPlacelistsToPlacesForIdQuery(relation.Id, {
    skip,
  });
  console.dir(answer);
  if (answer != null && answer.isSuccess) {
    return answer.data.records[0];
  }
  return relation;
}
export function useCompleteRelationUserDataPlaces(relation: any) {
  let skip = checkIfAllObjectsComplete([
    relation.userdata_id,
    relation.places_id,
  ]);
  const answer = useGetRelationUserDataToPlacesForIdQuery(relation.Id, {
    skip,
  });
  console.dir(answer);
  if (answer != null && answer.isSuccess) {
    return answer.data.records[0];
  }
  return relation;
}
export function useCompleteRelationUserDataPlacelists(relation: any) {
  let skip = checkIfAllObjectsComplete([
    relation.userdata_id,
    relation.placelists_id,
  ]);
  const answer = useGetRelationUserDataToPlacelistsForIdQuery(relation.Id, {
    skip,
  });
  console.dir(answer);
  if (answer != null && answer.isSuccess) {
    return answer.data.records[0];
  }
  return relation;
}

function checkIfObjectComplete(o: any) {
  return o != null && typeof o === "object";
}
function checkIfAllObjectsComplete(objects: any[]) {
  for (let index = 0; index < objects.length; index++) {
    const element = objects[index];
    if (!checkIfObjectComplete(element)) {
      return false;
    }
  }
  return true;
}
