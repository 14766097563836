import { FormatListNumberedTwoTone } from "@mui/icons-material";
import React from "react";
import useAppService from "../../services/AppService";
import { useCompleteRelationPlaceListPlaces } from "../../services/RelationsServices";
import { SmartChip } from "../chip/SmartChip";

type Props = {
  relation: any;
};

export function PlacelistsChip({ relation }: Props): JSX.Element {
  // const { tCapitalize } = useTranslationService();
  relation = useCompleteRelationPlaceListPlaces(relation);
  const { pathname } = useAppService();

  // const comment = relation.Comment;
  let placelist = relation.placelists_id;
  let name = placelist.Name;
  if (name == null) {
    name = "?";
  }
  // let tooltip = tCapitalize("placelist") + " " + name;
  // if (comment != null) {
  //   tooltip = comment;
  // }
  const url = "/placelist/" + placelist.Id;
  const disabled = url != null && url === pathname;
  return (
    <SmartChip
      key={"PlacelistsChip_" + name}
      text={name}
      icon={<FormatListNumberedTwoTone />}
      href={url}
      // tooltip={tooltip}
      disabled={disabled}
      color={disabled ? "success" : "primary"}
    />
  );
}
