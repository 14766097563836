import _ from "lodash";
import { isNullOrUndefined } from "./Utils";

export function concatArrayWithoutDuplicates(oldStuff: any[], newStuff: any[]) {
  var ret = oldStuff.concat(
    newStuff.filter((item) => oldStuff.indexOf(item) < 0)
  );
  return ret;
}

export function concatAllArraysWithoutDuplicates(arrays: any[]) {
  let ret = [];
  for (let index = 0; index < arrays.length; index++) {
    const array = arrays[index];
    ret = concatArrayWithoutDuplicates(ret, array);
  }
  return ret;
}

export function mergeArraysToNewArray(
  newSuff: any[],
  oldStuff: any[],
  key: _.ValueIteratee<any> | undefined
) {
  const ret = _.unionBy(newSuff, oldStuff, key);
  return ret;
}

export function updateArray(
  originalArray: any[],
  updateArray: any[],
  equalsCallback: any,
  doDeletes: boolean
) {
  if (isNullOrUndefined(doDeletes)) {
    doDeletes = true;
  }
  if (doDeletes) {
    // remove elements
    const copyOfOriginalArray = [...originalArray];
    copyOfOriginalArray.forEach((o) => {
      if (!arrayContainsElement(updateArray, o, equalsCallback)) {
        deleteAllOccurencesOfElementFromArray(originalArray, o, equalsCallback);
      }
    });
  }
  // add elements
  updateArray.forEach((o) => {
    if (!arrayContainsElement(originalArray, o, equalsCallback)) {
      addElementToArray(originalArray, o);
    }
  });
}

export function arrayContainsElement(
  array: any[],
  element: any,
  equalsCallback?: (arg0: any, arg1: any) => any
) {
  if (equalsCallback == null) {
    equalsCallback = (a, b) => {
      return a === b;
    };
  }
  for (let index = 0; index < array.length; index++) {
    const o = array[index];
    if (equalsCallback(o, element)) {
      return true;
    }
  }
  return false;
}

export function deleteAllOccurencesOfElementFromArray(
  array: any[],
  element: any,
  equalsCallback: (arg0: any, arg1: any) => any
) {
  for (let index = array.length - 1; index >= 0; index--) {
    const o = array[index];
    if (equalsCallback(o, element)) {
      array.splice(index, 1);
    }
  }
}
export function deleteOneOccurencesOfElementFromArray(
  array: any[],
  element: any,
  equalsCallback: (arg0: any, arg1: any) => any
) {
  let ret = 0;
  for (let index = array.length - 1; index >= 0; index--) {
    const o = array[index];
    if (equalsCallback(o, element)) {
      array.splice(index, 1);
      ret++;
      break;
    }
  }
  return ret;
}
export function removeDuplicatesFromArray(
  array: any[],
  equalsCallback: (arg0: any, arg1: any) => any
) {
  let duplicates;
  do {
    duplicates = findDuplicatesInArray(array, equalsCallback);
    for (let index = 0; index < duplicates.length; index++) {
      const dup = duplicates[index];
      deleteOneOccurencesOfElementFromArray(array, dup, equalsCallback);
    }
  } while (duplicates.length > 0);
}
export function findDuplicatesInArray(
  array: string | any[],
  equalsCallback: (arg0: any, arg1: any) => any
) {
  const ret = [];
  for (let index = 0; index < array.length; index++) {
    const o = array[index];
    const noO = findNumberOfOccurrencesInArray(array, o, equalsCallback);
    if (noO > 1) {
      if (findNumberOfOccurrencesInArray(ret, o, equalsCallback) < 1) {
        ret.push(o);
      }
    }
  }
  return ret;
}
export function findNumberOfOccurrencesInArray(
  array: string | any[],
  element: any,
  equalsCallback: {
    (arg0: any, arg1: any): any;
    (arg0: any, arg1: any): any;
    (arg0: any, arg1: any): any;
  }
) {
  let ret = 0;
  for (let index = 0; index < array.length; index++) {
    const o = array[index];
    if (equalsCallback(o, element)) {
      ret++;
    }
  }
  return ret;
}
export function addElementToArray(array: any[], element: any) {
  const length = array.length;
  array.splice(length, 0, element);
}
export function arrayToText(array: any[]) {
  const separator = ", ";
  const lastSeparator = " oder ";
  function wrap(text: string) {
    return "'" + text + "'";
  }
  const arrLength = array.length;
  if (arrLength === 0) {
    return "";
  }
  if (arrLength === 1) {
    return wrap(array[0]);
  }
  if (arrLength === 2) {
    return wrap(array[0]) + lastSeparator + wrap(array[1]);
  }
  let ret = "";
  for (let index = 0; index < array.length; index++) {
    const element = array[index];
    if (index === 0) {
      ret = wrap(element);
    } else if (index < array.length - 1) {
      ret = ret + separator + wrap(element);
    } else {
      ret = ret + lastSeparator + wrap(element);
    }
  }
  return ret;
}

// Function to return a random item from an array
export function randomItemFromArray(array: any[]) {
  // Use Math.random() to generate a random number between 0 and 1,
  // multiply it by the length of the array, and use Math.floor() to round down to the nearest integer
  return array[Math.floor(Math.random() * array.length)];
}

export function updateObjectInArrayWithObject(
  array: any[],
  object: any,
  checkEqual: (a: any, b: any) => boolean
): any[] {
  for (let index = 0; index < array.length; index++) {
    const element = array[index];
    if (checkEqual(element, object)) {
      const merged = { ...element, ...object };
      array[index] = merged;
    }
  }
  return array;
}

export function updateObjectsInArrayWithArray(
  array: any[],
  updateArray: any[],
  checkEqual: (a: any, b: any) => boolean
): any[] {
  for (let index = 0; index < updateArray.length; index++) {
    const element = updateArray[index];
    array = updateObjectInArrayWithObject(array, element, checkEqual);
  }
  return array;
}
