import React from "react";
import { ShowersChip } from "./ShowersChip";
import { IPlace } from "../../../stuff/AppModelTypes";
import { ToiletsChip } from "./ToiletsChip";
import { SwimmingChip } from "./SwimmingChip";
import { PetsAllowedChip } from "./PetsAllowedChip";
import {
  BLACK_WATER_DISPOSAL,
  FRESH_WATER_SUPPLY,
  GARBAGE_DISPOSAL,
  GRAY_WATER_DISPOSAL,
  WASHING_MACHINE,
  PETS_ALLOWED,
  POWER_CONNECTION,
  SHOWERS,
  SWIMMING_POOL,
  TOILETS,
  TUMBLE_DRYER,
  WASTE_WATER_CONNECTION,
  compareFeatures,
  FRESH_WATER_CONNECTION,
  WASHBOWL,
  WINTER_CARAVANNING,
  WIFI,
  TV_CONNECTION,
  GAS_BOTTLE_SERVICE,
  WASHING_MOTORHOME,
  PLAYGROUND,
  SHADING,
  GUARDING,
  BAKED_GOODS,
} from "../../../api/FeaturesConstants";
import { FreshWaterSupplyChip } from "./FreshWaterSupplyChip";
import { GrayWaterDisposalChip } from "./GrayWaterDisposalChip";
import { WashingMachineChip } from "./WashingMachineChip";
import { BlackWaterDisposalChip } from "./BlackWaterDisposalChip";
import { GarbageDisposalChip } from "./GarbageDisposalChip";
import { TumbleDryerChip } from "./TumbleDryerChip";
import { PowerConnectionChip } from "./PowerConnectionChip";
import { WasteWaterConnectionChip } from "./WasteWaterConnectionChip";
import { FreshWaterConnectionChip } from "./FreshWaterConnectionChip";
import { GenericFeatureChip } from "./GenericFeatureChip";
import { Stack } from "@mui/material";

type Props = {
  place: IPlace;
  available?: boolean;
};
export function FeaturesInfo({ place, available = true }: Props) {
  let ret = null;

  function _createChipObject(
    features: any[],
    featureConst: any,
    creatorCallback?: any
  ) {
    let c = null;
    const featureKey = featureConst.key;
    const f = _findFeature(features, featureKey);
    if (f != null) {
      if (creatorCallback != null) {
        c = creatorCallback(f);
      } else {
        c = (
          <GenericFeatureChip
            key={featureKey}
            featureConst={featureConst}
            available={available}
          />
        );
      }
    }
    return {
      chip: c,
      weight: featureConst.weight,
    };
  }

  if (place != null) {
    const faString = available
      ? place.FeaturesAvailable
      : place.FeaturesNotAvailable;
    if (faString != null) {
      const featuresList = JSON.parse(faString);
      const chipObjects: any[] = [];
      chipObjects.push(
        _createChipObject(featuresList, FRESH_WATER_SUPPLY, (f: any) => (
          <FreshWaterSupplyChip
            key={"FreshWaterSupplyChip"}
            feature={f}
            available={available}
          />
        ))
      );
      chipObjects.push(
        _createChipObject(featuresList, GRAY_WATER_DISPOSAL, (f: any) => (
          <GrayWaterDisposalChip
            key={"GrayWaterDisposalChip"}
            feature={f}
            available={available}
          />
        ))
      );
      chipObjects.push(
        _createChipObject(featuresList, BLACK_WATER_DISPOSAL, (f: any) => (
          <BlackWaterDisposalChip
            key={"BlackWaterDisposalChip"}
            feature={f}
            available={available}
          />
        ))
      );
      chipObjects.push(
        _createChipObject(featuresList, FRESH_WATER_CONNECTION, (f: any) => (
          <FreshWaterConnectionChip
            key={"FreshWaterConnectionChip"}
            feature={f}
            available={available}
          />
        ))
      );
      chipObjects.push(
        _createChipObject(featuresList, WASTE_WATER_CONNECTION, (f: any) => (
          <WasteWaterConnectionChip
            key={"WasteWaterConnectionChip"}
            feature={f}
            available={available}
          />
        ))
      );
      chipObjects.push(
        _createChipObject(featuresList, POWER_CONNECTION, (f: any) => (
          <PowerConnectionChip
            key={"PowerConnectionChip"}
            feature={f}
            available={available}
          />
        ))
      );
      chipObjects.push(
        _createChipObject(featuresList, GARBAGE_DISPOSAL, (f: any) => (
          <GarbageDisposalChip
            key={"GarbageDisposalChip"}
            feature={f}
            available={available}
          />
        ))
      );
      chipObjects.push(
        _createChipObject(featuresList, SHOWERS, (f: any) => (
          <ShowersChip key={"ShowersChip"} feature={f} available={available} />
        ))
      );
      chipObjects.push(
        _createChipObject(featuresList, TOILETS, (f: any) => (
          <ToiletsChip key={"ToiletsChip"} feature={f} available={available} />
        ))
      );
      chipObjects.push(
        _createChipObject(featuresList, PETS_ALLOWED, (f: any) => (
          <PetsAllowedChip
            key={"PetsAllowedChip"}
            feature={f}
            available={available}
          />
        ))
      );
      chipObjects.push(
        _createChipObject(featuresList, WASHING_MACHINE, (f: any) => (
          <WashingMachineChip
            key={"WashingMachineChip"}
            feature={f}
            available={available}
          />
        ))
      );
      chipObjects.push(
        _createChipObject(featuresList, TUMBLE_DRYER, (f: any) => (
          <TumbleDryerChip
            key={"TumbleDryerChip"}
            feature={f}
            available={available}
          />
        ))
      );
      chipObjects.push(_createChipObject(featuresList, WASHBOWL));
      chipObjects.push(_createChipObject(featuresList, WINTER_CARAVANNING));
      chipObjects.push(_createChipObject(featuresList, WIFI));
      chipObjects.push(_createChipObject(featuresList, TV_CONNECTION));
      chipObjects.push(_createChipObject(featuresList, BAKED_GOODS));
      chipObjects.push(_createChipObject(featuresList, GAS_BOTTLE_SERVICE));
      chipObjects.push(
        _createChipObject(featuresList, SWIMMING_POOL, (f: any) => (
          <SwimmingChip
            key={"SwimmingChip"}
            feature={f}
            available={available}
          />
        ))
      );
      chipObjects.push(_createChipObject(featuresList, WASHING_MOTORHOME));
      chipObjects.push(_createChipObject(featuresList, PLAYGROUND));
      chipObjects.push(_createChipObject(featuresList, SHADING));
      chipObjects.push(_createChipObject(featuresList, GUARDING));
      // here starts the filtering an sorting
      const filteredChipObjects = chipObjects.filter((o) => o.chip != null);
      if (filteredChipObjects.length < 1) {
        return null;
      }
      filteredChipObjects.sort((a, b) => compareFeatures(a, b));
      ret = (
        <Stack
          key="features_info"
          display="inline-flex"
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          alignContent="flex-start"
          spacing="4pt"
          flexWrap="wrap"
          flexGrow={1}
          flexShrink={1}
          useFlexGap
          flexBasis="0"
        >
          {filteredChipObjects.map((co) => {
            return co.chip;
          })}
        </Stack>
      );
    }
  }
  return ret;
}

function _findFeature(features: any, key: string) {
  for (let index = 0; index < features.length; index++) {
    const f = features[index];
    if (key === f.key) {
      return f;
    }
  }
  return null;
}
