import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import SmartAccordionLazy from "../../components/accordion/SmartAccordionLazy";
import { createSimpleGridContainer } from "../../components/containers/GridContainer";
import SmartLink from "../../components/others/SmartLink";
import i18n from "../../i18n";
import useTranslationService from "../../services/TranslationService";
import { useUserService } from "../../services/UserService";
import { fromDaysToSeconds } from "../../utils/Utils";
import { DAYS_ARRAY } from "./AbstractScrapeLinksSincePage";

type ModelElement = {
  headerTextId: string;
  childrenCallback: () => any;
  checkVisibility?: () => boolean;
};

export function NewScrapeLinksPage() {
  const { t, fromMilisecondsToTimeString } = useTranslationService();
  const { isSuperuser } = useUserService();
  const [listModel, setListModel] = useState<ModelElement[] | undefined>(
    undefined
  );

  useEffect(() => {
    if (isSuperuser == null || t == null) {
      return;
    }
    function createForScrapeLinksCreatedSinceDays(days: number) {
      const seconds = fromDaysToSeconds(days);
      const time = fromMilisecondsToTimeString(seconds * 1000);
      const text = i18n.format(
        t("new-scrape-links-from-the-last-time", { time: time }),
        "capitalize"
      );
      return (
        <SmartLink
          key={"SmartLink-new-scrape-links-" + days}
          href={"/newscrapelinkssince/" + seconds}
          internalLink={true}
          showAsButton={true}
        >
          {text}
        </SmartLink>
      );
    }
    function createForUpdatesdSinceDays(days: number) {
      const seconds = fromDaysToSeconds(days);
      const time = fromMilisecondsToTimeString(seconds * 1000);
      const text = i18n.format(
        t("updated-scrape-links-from-the-last-time", { time: time }),
        "capitalize"
      );
      return (
        <SmartLink
          key={"SmartLink-updated-scrape-links-" + days}
          href={"/updatedscrapelinkssince/" + seconds}
          internalLink={true}
          showAsButton={true}
        >
          {text}
        </SmartLink>
      );
    }
    function createForLastScrapedSinceDays(days: number) {
      const seconds = fromDaysToSeconds(days);
      const time = fromMilisecondsToTimeString(seconds * 1000);
      const text = i18n.format(
        t("last-scraped-scrape-links-from-the-last-time", { time: time }),
        "capitalize"
      );
      return (
        <SmartLink
          key={"SmartLink-last-scraped-scrape-links-" + days}
          href={"/lastscrapedscrapelinkssince/" + seconds}
          internalLink={true}
          showAsButton={true}
        >
          {text}
        </SmartLink>
      );
    }
    function createContentForNewScrapeLinks() {
      const content = DAYS_ARRAY.map((days) => {
        return createForScrapeLinksCreatedSinceDays(days);
      });
      return createSimpleGridContainer(content);
    }
    function createContentForUpdatedScrapeLinks() {
      const content = DAYS_ARRAY.map((days) => {
        return createForUpdatesdSinceDays(days);
      });
      return createSimpleGridContainer(content);
    }
    function createContentForLastScrapedScrapeLinks() {
      const content = DAYS_ARRAY.map((days) => {
        return createForLastScrapedSinceDays(days);
      });
      return createSimpleGridContainer(content);
    }

    const newListModel: ModelElement[] = [
      {
        headerTextId: "new-scrape-links",
        childrenCallback: createContentForNewScrapeLinks,
      },
      {
        headerTextId: "updated-scrape-links",
        childrenCallback: createContentForUpdatedScrapeLinks,
      },
      {
        headerTextId: "last-scraped-scrape-links",
        childrenCallback: createContentForLastScrapedScrapeLinks,
      },
    ];
    setListModel(newListModel);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuperuser, t]);

  function renderComponent(object: ModelElement) {
    if (object.checkVisibility == null || object.checkVisibility()) {
      return (
        <SmartAccordionLazy
          key={"SmartAccordionLazy-" + object.headerTextId}
          header={t(object.headerTextId)}
          childrenCallback={object.childrenCallback}
          defaultExpanded={true}
        />
      );
    } else {
      return null;
    }
  }

  return listModel != null ? (
    <>
      {listModel.map((m: any) => {
        return renderComponent(m);
      })}
    </>
  ) : (
    <Typography>{t("loading")}</Typography>
  );
}
