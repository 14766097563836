export const DRAWER_WIDTH = 160;

export const COLOR_BACKGROUND_DELETED = "rgba(255,220,220,1)";

export const sxForHeader: any = {
  transition: ".5s",
  "&:hover": {
    transform: "scale(1.025)",
    textTransform: "underline",
    transition: ".25s",
  },
};
export const sxForCard: any = {
  m: 1.5,
  borderRadius: 2,
  transition: ".5s",
  // "&:hover": {
  //   // color: "#ffee10",
  //   // box: "0 0 5px #ffee10",
  //   // box: "10px 10px 5px #ffee10",
  //   // text-shadow: "0 0 5px #ffee10",
  //   // transform: "scale(1.005)",
  //   transform: "scale(1.0025)",
  //   // transform: "rotate(.5deg)",
  //   // transform: "rotateZ(360deg)",
  //   // transform: "skew(.5deg)",
  //   // transform: "translate(0 0 200px)",
  //   // borderRadius: 5,
  //   // filter: "brightness(90%)",
  //   // filter: "brightness(92.5%) drop-shadow(5px 5px 3px rgba(0,0,0,0.5))",
  //   transition: ".25s",
  // },
};
export const sxForCardDeleted: any = {
  background: COLOR_BACKGROUND_DELETED,
  ...sxForCard,
};
