import { Card, CardContent, CardHeader, Stack } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useCheckIfPlaceIsDeleted } from "../../services/PlaceService";
import { findRelationInArray } from "../../services/PlacelistsService";
import { useGetRelationsPlace2Placelists } from "../../services/RelationsServices";
import useTranslationService from "../../services/TranslationService";
import { IPlace, IPlacelist } from "../../stuff/AppModelTypes";
import { sxForCard, sxForCardDeleted } from "../../stuff/StyleConstants";
import {
  fromKmNumberToDistanceText,
  gpsCoordsToDistanceInKm,
} from "../../utils/Utils";
import { PlaceInfo } from "../info/PlaceInfo";
import { FeaturesInfo } from "../info/features/FeaturesInfo";
import { CountriesMapForPlaces } from "../maps/CountriesMapForPlaces";
import { MapImageForPlace } from "../maps/MapImageForPlace";
import { MarkerVisualizations } from "../maps/SvgUtils";
import { TitleLink } from "../others/TitleLink";
import { CommentForPlaceinPlacelist } from "./CommentForPlaceinPlacelist";

type IProps = {
  placeObject: IPlace;
  parent?: IPlacelist;
  enableFlyTo?: boolean;
  fromLatiude?: number;
  fromLongitude?: number;
  infoVisibility?: "expanded" | "collapsed" | "hidden";
};

const MAP_HEIGHT = "150px";

function PlaceSmall({
  placeObject,
  parent,
  enableFlyTo,
  fromLatiude,
  fromLongitude,
  infoVisibility = "collapsed",
}: IProps) {
  const { tCapitalize } = useTranslationService();
  const placeId = placeObject.Id;
  const isPlaceDeleted = useCheckIfPlaceIsDeleted(placeObject);
  const relations = useGetRelationsPlace2Placelists(placeObject ?? skipToken);
  // const { onePlaceActions } = usePlaceActions(placeObject);
  const [distanceFrom, setDistanceFrom] = useState<number | undefined>(
    undefined
  );
  const isBigScreen = useMediaQuery({ query: "(min-width: 750px)" });
  const isSmallScreen = useMediaQuery({ query: "(max-width: 400px)" });

  useEffect(() => {
    if (placeObject && fromLatiude != null && fromLongitude != null) {
      const lat = placeObject.Latitude;
      const lon = placeObject.Longitude;
      if (lat != null && lon != null) {
        const distanceInKm = gpsCoordsToDistanceInKm(
          lon,
          lat,
          fromLongitude,
          fromLatiude
        );
        setDistanceFrom(distanceInKm);
      }
    } else {
      setDistanceFrom(undefined);
    }
  }, [fromLatiude, fromLongitude, placeObject]);

  let subheaderText = null;
  if (distanceFrom != null) {
    subheaderText =
      tCapitalize("distance") + ": " + fromKmNumberToDistanceText(distanceFrom);
  }

  // const actionBar = (
  //   <SmartActionBar actions={onePlaceActions} showLabels={false} />
  // );

  let ret = null;
  if (placeObject) {
    const cardMaps = (
      <Stack
        key="card-maps"
        // direction={isBigScreen ? "row" : "column"}
        direction={isSmallScreen ? "column" : "row"}
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={"4pt"}
      >
        <MapImageForPlace place={placeObject} height={MAP_HEIGHT} />
        {
          // isBigScreen &&
          <CountriesMapForPlaces
            places={[placeObject]}
            disableMarkerClustering={true}
            markerVisualization={MarkerVisualizations.circle}
            height={MAP_HEIGHT}
          />
        }
      </Stack>
    );
    const cardContent =
      infoVisibility === "hidden" ? null : (
        <CardContent sx={{ padding: 2 }}>
          <Stack
            flexGrow={1}
            display="flex"
            direction={isBigScreen ? "row" : "column"}
            flexWrap="wrap"
            // columnGap={2}
            gap={1}
          >
            {cardMaps}
            <PlaceInfo place={placeObject} />
            <Stack
              key="stack_for_features"
              // direction={isBigScreen ? "row" : "column"}
              direction={"column"}
              gap={1}
              flexWrap="wrap"
              useFlexGap
            >
              <FeaturesInfo place={placeObject} />
              <FeaturesInfo place={placeObject} available={false} />
            </Stack>
          </Stack>
        </CardContent>
      );

    let relation = null;
    if (parent != null && relations != null) {
      relation = findRelationInArray(relations, parent);
    }

    const titleText =
      placeObject.Name != null ? placeObject.Name : "<" + placeId + ">";
    const href = "/place/" + placeId;

    const cardHeaderTitle = (
      <Stack direction="column">
        {relation && <CommentForPlaceinPlacelist relation={relation} />}
        <TitleLink text={titleText} href={href} />
        {/* </CardActionArea> */}
      </Stack>
    );

    const cardHeader = (
      <CardHeader
        title={cardHeaderTitle}
        titleTypographyProps={{
          variant: "h4",
        }}
        sx={{ pb: 0 }}
        subheader={subheaderText}
      />
    );
    ret = (
      <Card
        raised={true}
        elevation={5}
        id={placeId}
        sx={isPlaceDeleted ? sxForCardDeleted : sxForCard}
      >
        {cardHeader}
        <Stack
          flexGrow={1}
          display="flex"
          flexDirection="row"
          // flexWrap="wrap"
          columnGap={2}
        >
          {/* <CardMedia>{cardMaps}</CardMedia> */}
          {cardContent}
        </Stack>
        {/* <SmartCardActions>{actionBar}</SmartCardActions> */}
      </Card>
    );
  }
  return ret;
}

export default PlaceSmall;
