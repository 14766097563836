import React, { useEffect, useState } from "react";
import {
  useGetAllPlaceAttachmentsQuery,
  useGetPlacesJoinFilteredQuery,
} from "../../api/PlacesApiSlice.js";
import { useUserService } from "../../services/UserService";
import { SmartQueryList } from "../lists/SmartQueryList";
import { createPlaceComponent } from "./PlaceRenderUtils";

interface IProps {
  queryArguments: any;
  pageSize?: number;
  fromLatiude?: number;
  fromLongitude?: number;
  infoVisibility?: "expanded" | "collapsed" | "hidden";
  updateCount?: (count: null | number) => void;
}

export default function PlacesResultList({
  queryArguments,
  pageSize,
  fromLatiude,
  fromLongitude,
  infoVisibility,
  updateCount,
}: IProps): JSX.Element | null {
  const [queryArgs, setQueryArgs] = useState<null | any>();
  const { isSuperuser, isServiceReady } = useUserService();
  const skipPlaceAttachmentQuery = !isServiceReady || !isSuperuser;
  const { placeAttachmentsData } = useGetAllPlaceAttachmentsQuery(null, {
    skip: skipPlaceAttachmentQuery,
  });
  // const {
  //   data: placesData,
  //   /*isLoading,*/ isFetching,
  //   isSuccess,
  //   isError,
  //   error,
  // } = useGetPlacesFilteredQuery(queryArgs ?? skipToken);

  useEffect(() => {
    if (isServiceReady) {
      const qaTemp = { ...queryArguments };
      if (queryArguments != null && placeAttachmentsData != null) {
        const specialHandling =
          isSuperuser && queryArguments.showPlacesWithoutAttachment === false;
        if (specialHandling) {
          const placeIdList = [];
          const placeAttachments = placeAttachmentsData.records;
          for (let index = 0; index < placeAttachments.length; index++) {
            const ap = placeAttachments[index];
            placeIdList.push(ap.PlaceId);
          }
          if (queryArguments.ids == null) {
            qaTemp.ids = placeIdList;
          }
        }
      }
      setQueryArgs(qaTemp);
    }
  }, [isSuperuser, placeAttachmentsData, queryArguments, isServiceReady]);

  function renderComponent(element: any) {
    return createPlaceComponent(element, {
      fromLatiude,
      fromLongitude,
      infoVisibility,
    });
  }
  const query = useGetPlacesJoinFilteredQuery;

  return isServiceReady && queryArgs != null ? (
    <SmartQueryList
      queryArguments={queryArgs}
      query={query}
      updateCount={updateCount}
      renderObject={renderComponent}
    />
  ) : (
    <p>loading...</p>
  );
}
