import React from "react";
import { WASTE_WATER_CONNECTION } from "../../../api/FeaturesConstants";
import { FeatureChip, FeatureChipSubtypeProps } from "./FeatureChip";

export function WasteWaterConnectionChip({
  feature,
  available,
}: FeatureChipSubtypeProps) {
  return (
    <FeatureChip
      feature={feature}
      featureConst={WASTE_WATER_CONNECTION}
      available={available}
    />
  );
}
