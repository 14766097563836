import { Typography } from "@mui/material";
import React from "react";
import usePlacelistActions from "../../actions/placelist/PlacelistActions";
import { usePlacelistsService } from "../../services/PlacelistsService";
import { IPlacelist } from "../../stuff/AppModelTypes";
import { compareStrings } from "../../utils/Utils";
import SmartActionBar from "../buttons/SmartActionBar";
import PlacelistSmall from "./PlacelistSmall";

interface IProps {
  userId?: string;
}

function PlacelistsForUserComponent({ userId }: IProps): JSX.Element | null {
  let listOfPlacelists = <Typography>Keine Placelist vorhanden</Typography>;
  const { placelists } = usePlacelistsService(userId);
  if (placelists != null && placelists.length > 0) {
    const sortedPlaceLists = placelists.slice().sort(compareNames); // slice() is needed because RTK array is not mutable
    listOfPlacelists = (
      <>
        {sortedPlaceLists.map((placelist: IPlacelist) => {
          return <PlacelistSmall key={placelist.Id} placelist={placelist} />;
        })}
      </>
    );
  }

  const { overPlacelistActions } = usePlacelistActions();
  const actionbar = (
    <SmartActionBar
      accordionOpen={true}
      actions={overPlacelistActions}
      showLabels={true}
    />
  );
  const ret = (
    <>
      {actionbar}
      {listOfPlacelists}
    </>
  );

  return ret;
}

export default PlacelistsForUserComponent;

///////////////////////////////////////////////////

function compareNames(a: IPlacelist, b: IPlacelist) {
  if (a != null && b != null) {
    let x = a.Name;
    let y = b.Name;
    return compareStrings(x, y);
  }
  return 0;
}
