import { skipToken } from "@reduxjs/toolkit/dist/query";
import React, { useCallback, useEffect, useState } from "react";
import { concatArrays, isNullOrUndefined } from "../../utils/Utils";
import { SmartList } from "./SmartList";

interface IProps {
  query: any;
  queryArguments: any;
  pageSize?: number;
  updateCount?: (count: null | number) => void;
  renderComponent: (element: any, index: number) => any;
}

export default function GenericQueryList({
  query,
  queryArguments,
  pageSize = 5,
  updateCount,
  renderComponent,
}: IProps): JSX.Element | null {
  const [storedQueryArguments, setStoredQueryArguments] = useState<null | any>(
    undefined
  );
  const [storedPageSize, setStoredPageSize] = useState<undefined | number>(
    undefined
  );
  const [internalQueryArgs, setInternalQueryArgs] = useState<null | any>(
    undefined
  );
  const [allElementsCount, setAllElementsCount] = useState<null | number>(null);
  const [elements, setElements] = useState<any[]>([]);
  const {
    data: elementsData,
    /*isLoading,*/
    isFetching,
    isSuccess,
    isError,
    error,
  } = query(internalQueryArgs ?? skipToken);

  useEffect(() => {
    if (isNullOrUndefined(queryArguments) || isNullOrUndefined(pageSize)) {
      return;
    }
    let initNeeded = false;
    if (storedQueryArguments !== queryArguments) {
      setStoredQueryArguments(queryArguments);
      initNeeded = true;
    }
    if (storedPageSize !== pageSize) {
      setStoredPageSize(pageSize);
      initNeeded = true;
    }
    if (initNeeded) {
      setElements([]);
      setAllElementsCount(null);
      if (updateCount != null) {
        updateCount(null);
      }
      const initialQueryArgs = { ...queryArguments, page: 1, pageSize };
      setInternalQueryArgs(initialQueryArgs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize, queryArguments, storedPageSize, storedQueryArguments]);

  //updates the elements based on the changes in elementsData.records, either by concatenating the arrays or replacing the value entirely
  useEffect(() => {
    if (elementsData != null) {
      const moreElements = elementsData.records;
      if (moreElements != null) {
        setElements((old) => concatArrays(old, moreElements));
      }
    }
  }, [elementsData]);

  // update allResultsCount and call the callback
  useEffect(() => {
    if (elementsData) {
      const oldCount = allElementsCount;
      const newCount = elementsData.results;
      if (oldCount !== newCount) {
        setAllElementsCount(newCount);
        if (updateCount != null) {
          updateCount(newCount);
        }
      }
    }
  }, [allElementsCount, elementsData, updateCount]);

  const loadMore = useCallback(() => {
    if (isFetching) {
      return;
    }
    if (allElementsCount == null || elements.length >= allElementsCount) {
      return;
    }
    const newQueryArgs = { ...internalQueryArgs };
    const nextPage = newQueryArgs.page + 1;
    newQueryArgs.page = nextPage;
    setInternalQueryArgs(newQueryArgs);
  }, [allElementsCount, elements.length, internalQueryArgs, isFetching]);

  // function Footer() {
  //   return <Typography>{t("end")}</Typography>;
  // }

  let content = null;
  if (isSuccess) {
    content = (
      <SmartList
        data={elements}
        renderComponent={(index, data) => {
          return renderComponent(data, index);
        }}
        endReached={loadMore}
        // footer={Footer}
      />
    );
  } else if (isError) {
    console.log(" Error");
    content = <div>{error.toString()}</div>;
  }
  return content;
}
