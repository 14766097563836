export function countChecked(list: any[]) {
  let ret = 0;
  for (let index = 0; index < list.length; index++) {
    const f = list[index];
    if (true === f.checked) {
      ret++;
    }
  }
  return ret;
}

export function createProvidersDecoration(providers: any[]) {
  const checkCount = countChecked(providers);
  const allCount = providers.length;
  if (checkCount <= 0 || checkCount === allCount) {
    return null;
  }
  return checkCount + "/" + allCount;
}
