import { Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useCheckIfPlaceIsDeleted } from "../../services/PlaceService";
import { useGetScrapeLinksForPlace } from "../../services/ScrapeLinksService";
import { IPlace } from "../../stuff/AppModelTypes";
import { isNullOrUndefined } from "../../utils/Utils";
import { usePlaceAttachmentsService } from "../attachments/PlaceAttachmentsService";
import { CountryChip } from "./CountryChip";
import { DeletedChip } from "./DeletedChip";
import { FavoriteChip } from "./FavoriteChip";
import { GpsChip } from "./GpsChip";
import { MapChip } from "./MapChip";
import {
  getCoordinatesForPlace,
  isLocationInaccurateForPlace,
} from "./NavigationUtils";
import { PlaceAttachmentChip } from "./PlaceAttachmentChip";
import { PlacelistsChips } from "./PlacelistsChips";
import { PriceChip } from "./PriceChip";
import { ProviderChip } from "./ProviderChip";
import { ShareChip } from "./ShareChip";

type Props = {
  place: IPlace;
  hideFlyTo?: boolean;
};
export function PlaceInfo({ place, hideFlyTo }: Props) {
  let ret = null;
  const isPlaceDeleted = useCheckIfPlaceIsDeleted(place);
  const scrapeLinks = useGetScrapeLinksForPlace(place);
  const { placeAttachments } = usePlaceAttachmentsService(place);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [placePrice, setPlacePrice] = useState();
  const [originalPlacePrice, setOriginalPlacePrice] = useState<
    string | undefined
  >();

  useEffect(() => {
    if (scrapeLinks != null) {
      const firstRecord = scrapeLinks[0];
      if (firstRecord) {
        const dataString = firstRecord.Data;
        if (dataString) {
          const data = JSON.parse(dataString);
          if (!isNullOrUndefined(data.preis)) {
            setPlacePrice(data.preis);
          }
          if (!isNullOrUndefined(data.preis_original)) {
            setOriginalPlacePrice(data.preis_original);
          }
        }
      }
    }
  }, [scrapeLinks]);

  let placeAttachmentsInfo = null;
  if (placeAttachments != null) {
    placeAttachmentsInfo = placeAttachments.map((pa) => {
      return (
        <PlaceAttachmentChip
          placeAttachment={pa}
          key={"placeAttachment_" + pa.Id}
        />
      );
    });
  }

  if (place != null) {
    // console.dir(place);
    const countryInfo =
      place.Land != null ? <CountryChip countryCode={place.Land} /> : null;
    const priceInfo =
      !isPlaceDeleted && place.Preis != null ? (
        <PriceChip
          price={place.Preis}
          originalPlacePrice={originalPlacePrice}
        />
      ) : null;
    const mapInfo = !isPlaceDeleted && !hideFlyTo && <MapChip place={place} />;
    let providerInfo = null;
    if (!isPlaceDeleted && scrapeLinks != null) {
      providerInfo = scrapeLinks.map((sl) => {
        if (sl == null) {
          return null;
        }
        return <ProviderChip key={"provider_" + sl.Url} url={sl.Url} />;
      });
    }
    let gpsInfo = null;
    const coords = getCoordinatesForPlace(place);
    if (!isPlaceDeleted && coords != null) {
      gpsInfo = (
        <GpsChip
          coords={coords}
          inaccurate={isLocationInaccurateForPlace(place)}
        />
      );
    }
    ret = (
      <Stack
        key="upper_stack"
        display="inline-flex"
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        alignContent="flex-start"
        spacing="4pt"
        flexWrap="wrap"
        flexGrow={1}
        flexShrink={0}
        useFlexGap
        flexBasis="0"
      >
        <DeletedChip place={place} />
        {countryInfo}
        {priceInfo}
        {mapInfo}
        <FavoriteChip place={place} />
        <PlacelistsChips place={place} />
        {providerInfo}
        {gpsInfo}
        {placeAttachmentsInfo}
        {!isPlaceDeleted && <ShareChip place={place} />}
      </Stack>
    );
  }
  return ret;
}
