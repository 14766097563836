import { FavoriteBorderTwoTone } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import React from "react";
import { useCheckIfPlaceIsDeleted } from "../../services/PlaceService";
import useTranslationService from "../../services/TranslationService";
import { useUserService } from "../../services/UserService";
import { IPlace } from "../../stuff/AppModelTypes";
import { SmartChip } from "../chip/SmartChip";

type Props = {
  place: IPlace;
};

export function FavoriteChip({ place }: Props): JSX.Element | null {
  const { tCapitalize } = useTranslationService();
  const isPlaceDeleted = useCheckIfPlaceIsDeleted(place);
  let ret = null;
  const placeId = place.Id;
  const userService = useUserService();
  const { enqueueSnackbar } = useSnackbar();

  function handleClick(): void {
    const isFavorite = userService.isFavoritePlace(placeId);
    if (isFavorite) {
      userService.removeFavoritePlace(placeId);
      enqueueSnackbar(
        tCapitalize("the-place-is-no-longer-one-of-your-favorites"),
        {
          variant: "info",
        }
      );
    } else {
      userService.addFavoritePlace(placeId);
      enqueueSnackbar(tCapitalize("the-place-is-now-one-of-your-favorites"), {
        variant: "info",
      });
    }
  }

  if (userService != null && userService.isAuthenticated) {
    const isFavoritePlace = userService.isFavoritePlace(placeId);
    const newColor = isFavoritePlace ? "error" : undefined;
    if (!isPlaceDeleted || isFavoritePlace) {
      ret = (
        <SmartChip
          text={tCapitalize("favourite")}
          icon={<FavoriteBorderTwoTone />}
          onClick={handleClick}
          color={newColor}
        />
      );
    }
  }
  return ret;
}
