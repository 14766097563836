import { Grid } from "@mui/material";
import React from "react";
import { ContainerProps } from "./ContainerProps";

const GridContainer = <T extends {}>({
  items = [],
  renderProps,
  renderItem,
}: ContainerProps<T>) => {
  const itemUIs = items.map((item, i) => {
    const obj = { ...renderProps };
    obj.item = item;
    return (
      <Grid item key={i}>
        {renderItem(item, obj)}
      </Grid>
    );
  });

  return (
    <Grid
      container
      // columns={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 5 }}
      spacing={{ xs: 1, xl: 2 }}
    >
      {itemUIs}
    </Grid>
  );
};

export function createSimpleGridContainer(content: React.JSX.Element[]) {
  return (
    <GridContainer
      items={content}
      renderItem={function (item): React.ReactNode {
        return item;
      }}
    />
  );
}

export default GridContainer;
