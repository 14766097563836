import { StyledContent } from "../components/styles/styled.Content";
import { SettingsForm } from "../components/settings/SettingsForm";

export function SettingsPage() {
  return (
    <StyledContent>
      <SettingsForm />
    </StyledContent>
  );
}
