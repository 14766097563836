import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

interface IParams {
  open: boolean;
  oldValue: string;
  onOk?: (newValue: string) => void;
  onClose?: () => void;
  title?: string;
  label?: string;
}

function RenameDialog(props: IParams) {
  const { t } = useTranslation();
  const [value, setValue] = useState(props.oldValue);
  const title = props.title ? props.title : t("rename");
  const label = props.label ? props.label : t("name");
  function handleClose() {
    if (props.onClose) {
      props.onClose();
    }
  }
  function handleOk() {
    if (props.onOk) {
      props.onOk(value);
    }
  }

  return (
    <div>
      <Dialog
        onClose={props.onClose}
        open={props.open}
        scroll="paper"
        fullScreen
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent dividers>
          {/* <SmartAttribute label="Name" value={p.oldValue} /> */}
          <TextField
            key="text"
            fullWidth
            label={label}
            defaultValue={props.oldValue}
            InputProps={{
              readOnly: false,
            }}
            margin="dense"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setValue(event.target.value);
            }}
            // size="small"
            // helperText={helperText}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>t('cancel')</Button>
          <Button
            disabled={
              value == null || value.length < 1 || value === props.oldValue
            }
            onClick={handleOk}
          >
            t('save'){" "}
          </Button>
        </DialogActions>{" "}
      </Dialog>
    </div>
  );
}

export default RenameDialog;
