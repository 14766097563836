import React from "react";
import { SHOWERS } from "../../../api/FeaturesConstants";
import { FeatureChip, FeatureChipSubtypeProps } from "./FeatureChip";

export function ShowersChip({ feature, available }: FeatureChipSubtypeProps) {
  return (
    <FeatureChip
      feature={feature}
      featureConst={SHOWERS}
      available={available}
    />
  );
}
