import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useGetAllCountriesQuery } from "../../api/PlacesApiSlice.js";
import { recallCountries, storeCountries } from "../../services/SettingsService.ts";
import useTranslationService from "../../services/TranslationService.tsx";
import SmartAccordion from "../accordion/SmartAccordion";
import {
  MAX_PLACE_OPTIONS,
  MAX_PRICE_OPTIONS,
} from "../places/PlacesConstants.ts";
import { useUserService } from "./../../services/UserService";
import MultiCheckboxComponent from "./../others/MultiCheckboxComponent";
import { FeatureSettingsForm } from "./FeatureSettingsForm";
import { LanguageSwitch } from "./LanguageSwitch";
import { ProviderSettingsForm } from "./ProviderSettingsForm";

export function SettingsForm() {
  const { t, tCountryNameFromCountryCode } = useTranslationService();
  const storedMaxPlaceCount = localStorage.getItem("maxPlaceCount");
  const [maxPlaceCount, setMaxPlaceCount] = useState(
    storedMaxPlaceCount ? storedMaxPlaceCount : MAX_PLACE_OPTIONS[3].value
  );
  const { isSuperuser } = useUserService();

  const [countries, setCountries] = useState(recallCountries());

  const storedMaxPrice = localStorage.getItem("maxPrice");
  const [maxPrice, setMaxPrice] = useState(
    storedMaxPrice
      ? storedMaxPrice
      : MAX_PRICE_OPTIONS[MAX_PRICE_OPTIONS.length - 1].value
  );

  const storedShowPlacesWithoutPriceInfo = localStorage.getItem(
    "showPlacesWithoutPriceInfo"
  );
  const [showPlacesWithoutPriceInfo, setShowPlacesWithoutPriceInfo] = useState(
    storedShowPlacesWithoutPriceInfo != null
      ? JSON.parse(storedShowPlacesWithoutPriceInfo)
      : true
  );

  const storedShowPlacesWithoutAttachment = localStorage.getItem(
    "showPlacesWithoutAttachment"
  );
  const [showPlacesWithoutAttachment, setShowPlacesWithoutAttachment] =
    useState(
      storedShowPlacesWithoutAttachment != null
        ? JSON.parse(storedShowPlacesWithoutAttachment)
        : true
    );

  const storedInfoFlag = localStorage.getItem("info");
  const [infoFlag, setInfoFlag] = useState(
    storedInfoFlag !== undefined ? JSON.parse(storedInfoFlag) : false
  );

  const { data: allCountries } = useGetAllCountriesQuery();

  function changeCountries(elements) {
    const currentCountries = _checkboxElements2Countries(elements);
    setCountries(currentCountries);
    storeCountries(currentCountries);
  }
  function changeShowPlacesWithoutPriceInfo(e) {
    if (e && e.target) {
      const target = e.target;
      const checked = target.checked;
      setShowPlacesWithoutPriceInfo(checked);
    }
  }
  function changeShowPlacesWithoutAttachment(e) {
    if (e && e.target) {
      const target = e.target;
      const checked = target.checked;
      setShowPlacesWithoutAttachment(checked);
    }
  }
  function changeInfoFlag(e) {
    if (e && e.target) {
      const target = e.target;
      const checked = target.checked;
      setInfoFlag(checked);
      const stringified = JSON.stringify(checked);
      localStorage.setItem("info", stringified);
    }
  }

  useEffect(() => {
    localStorage.setItem("maxPlaceCount", maxPlaceCount);
    localStorage.setItem("maxPrice", maxPrice);
    localStorage.setItem(
      "showPlacesWithoutPriceInfo",
      showPlacesWithoutPriceInfo
    );
    localStorage.setItem(
      "showPlacesWithoutAttachment",
      showPlacesWithoutAttachment
    );
  }, [
    maxPlaceCount,
    maxPrice,
    showPlacesWithoutPriceInfo,
    showPlacesWithoutAttachment,
  ]);
  const countryEntries = _countries2CheckboxElements(allCountries, countries);

  function _countries2CheckboxElements(allCountryKeys, checkedCountries) {
    const ret = [];
    if (allCountryKeys) {
      for (let i = 0; i < allCountryKeys.length; i++) {
        const countryKey = allCountryKeys[i];
        const checked =
          checkedCountries && checkedCountries.includes(countryKey);
        const newElement = {
          id: countryKey,
          label: tCountryNameFromCountryCode(countryKey),
          checked: checked,
        };
        ret.push(newElement);
      }
    }
    return ret;
  }

  return (
    <>
      <SmartAccordion header={t("language")} defaultExpanded={true}>
        <FormControl fullWidth>
          <InputLabel id="langauge-select-label">
            {t("current-language")}
          </InputLabel>
          <LanguageSwitch />
        </FormControl>
      </SmartAccordion>
      <SmartAccordion header={t("number")} defaultExpanded={true}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">
            {t("maximum-places-displayed")}
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={maxPlaceCount}
            label={t("maximum-places-displayed")}
            onChange={(e) => setMaxPlaceCount(e.target.value)}
          >
            {MAX_PLACE_OPTIONS &&
              MAX_PLACE_OPTIONS.map((o) => (
                <MenuItem key={o.value} value={o.value}>
                  {o.label}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </SmartAccordion>
      <SmartAccordion header={t("features")} defaultExpanded={true}>
        <FeatureSettingsForm key="Features" />
      </SmartAccordion>
      <SmartAccordion header={t("provider")} defaultExpanded={true}>
        <ProviderSettingsForm key="Providers" />
      </SmartAccordion>
      <SmartAccordion header={t("price")} defaultExpanded={true}>
        <FormControl fullWidth>
          <InputLabel id="maxPrice-select-label">
            {t("maximum-price")}
          </InputLabel>
          <Select
            labelId="maxPrice-select-label"
            id="maxPrice-select"
            value={maxPrice}
            label={t("maximum-price")}
            onChange={(e) => setMaxPrice(e.target.value)}
          >
            {MAX_PRICE_OPTIONS &&
              MAX_PRICE_OPTIONS.map((o) => (
                <MenuItem key={o.key} value={o.value}>
                  {o.label}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <FormControlLabel
          control={
            <Checkbox
              checked={showPlacesWithoutPriceInfo}
              onChange={changeShowPlacesWithoutPriceInfo}
            />
          }
          label={t("show-places-without-price-information")}
        />
      </SmartAccordion>
      {isSuperuser ? (
        <SmartAccordion header={t("attachments")} defaultExpanded={true}>
          <FormControlLabel
            control={
              <Checkbox
                checked={showPlacesWithoutAttachment}
                onChange={changeShowPlacesWithoutAttachment}
              />
            }
            label={t("show-places-without-attachments")}
          />
        </SmartAccordion>
      ) : null}
      <SmartAccordion header={t("countries")} defaultExpanded={false}>
        <MultiCheckboxComponent
          key="MultiCheckboxComponent_countries"
          entries={countryEntries}
          onChange={changeCountries}
        />
      </SmartAccordion>
      {isSuperuser && (
        <SmartAccordion header={t("debug")} defaultExpanded={true}>
          <FormControlLabel
            control={<Checkbox checked={infoFlag} onChange={changeInfoFlag} />}
            label={t("show-debug-information")}
          />
        </SmartAccordion>
      )}
    </>
  );
}

function _checkboxElements2Countries(checkboxElements) {
  const ret = [];
  if (checkboxElements) {
    for (let i = 0; i < checkboxElements.length; i++) {
      const e = checkboxElements[i];
      const checked = e.checked ? true : false;
      if (checked) {
        const newCountry = e.id;
        ret.push(newCountry);
      }
    }
  }
  return ret;
}
