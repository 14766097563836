import React, { useState } from "react";
import { UserdataPlacelistsRole } from "../../api/DbConstants";
import { useGetUserRoleFromPlacelist } from "../../services/PlacelistService";
import useTranslationService from "../../services/TranslationService";
import { useUserService } from "../../services/UserService";
import { IPlacelist } from "../../stuff/AppModelTypes";
import { arrayContainsElement } from "../../utils/ArrayUtils2";
import { SmartChip } from "../chip/SmartChip";
import RenamePlacelistDialog from "./RenamePlacelistDialog";

type Props = {
  placelist: IPlacelist;
};

export function RenamePlacelistChip({ placelist }: Props) {
  const { tCapitalize } = useTranslationService();
  const { isSuperuser } = useUserService();
  const userRole = useGetUserRoleFromPlacelist(placelist);
  const [dialogOpen, setDialogOpen] = useState(false);

  function handleClick(event: any) {
    setDialogOpen(true);
  }

  function canEditPlacelist() {
    return (
      isSuperuser ||
      arrayContainsElement(
        [UserdataPlacelistsRole.Editor, UserdataPlacelistsRole.Admin],
        userRole
      )
    );
  }

  const enabled = canEditPlacelist();

  if (enabled) {
    return (
      <>
        <SmartChip
          text={tCapitalize("rename")}
          tooltip={tCapitalize("rename_placelist")}
          onClick={handleClick}
          disabled={!enabled}
        />
        <RenamePlacelistDialog
          open={dialogOpen}
          title={tCapitalize("rename_placelist")}
          placelist={placelist}
          onClose={() => {
            setDialogOpen(false);
          }}
        />
      </>
    );
  } else {
    return null;
  }
}
