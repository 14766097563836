import React, { useEffect, useState } from "react";
import useTranslationService from "../../services/TranslationService";
import { boxAroundPoint, isNullOrUndefined } from "../../utils/Utils";
import SmartAccordion from "../accordion/SmartAccordion";
import PlacesResultList from "./PlacesResultList";
import PlacesResultMap from "./PlacesResultMap";

interface IProps {
  longitude: number;
  latitude: number;
  distanceInKm: number;
  additionalQueryProps?: any;
  excludePlaceIds?: string[];
  defaultExpanded?: boolean;
}

function NearbyPlacesRadius({
  longitude,
  latitude,
  distanceInKm,
  additionalQueryProps=null,
  excludePlaceIds,
  defaultExpanded,
}: IProps): JSX.Element | null {
  const { t, tCapitalize } = useTranslationService();
  const [queryProps, setQueryProps] = useState<any>(null);
  const [allCount, setAllCount] = useState<number | null>(null);

  useEffect(() => {
    if (
      !isNullOrUndefined(longitude) &&
      !isNullOrUndefined(latitude) &&
      !isNullOrUndefined(distanceInKm)
    ) {
      const box = boxAroundPoint(latitude, longitude, distanceInKm);
      const queryArguments = {
        bounds: {
          west: box.lat1,
          east: box.lat2,
          south: box.lon1,
          north: box.lon2,
        },
        excludeIds: excludePlaceIds,
      };
      const completeProps = {...queryArguments, ...additionalQueryProps};
      setQueryProps(completeProps);
    }
  }, [additionalQueryProps, distanceInKm, excludePlaceIds, latitude, longitude]);

  function _updateCountCallback(count: null | number): void {
    setAllCount(count);
  }

  let pre = t("places");
  if (allCount === null) {
    pre = t("places");
  } else if (allCount === 0) {
    pre = t("no-places");
  } else if (allCount === 1) {
    pre = t("one-place");
  } else if (allCount > 1) {
    pre = allCount + " " + t("places");
  }
  const text = pre + " " + t("within") + " " + distanceInKm + " " + t("km");

  return (
    <SmartAccordion
      header={text}
      defaultExpanded={defaultExpanded}
      disabled={allCount != null && allCount < 1}
    >
      <PlacesResultMap
        queryArguments={queryProps}
        updateCount={_updateCountCallback}
      />
      <SmartAccordion header={tCapitalize("list")}>
        <PlacesResultList
          queryArguments={queryProps}
          updateCount={_updateCountCallback}
          fromLatiude={latitude}
          fromLongitude={longitude}
        />
      </SmartAccordion>
    </SmartAccordion>
  );
}

export default NearbyPlacesRadius;
