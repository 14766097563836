import { MenuItem, Select } from "@mui/material";
import useTranslationService from "../../services/TranslationService";
import { LANGUAGES } from "../places/PlacesConstants";
import { compareStrings } from "../../utils/Utils";
import React from "react";

export function LanguageSwitch() {
  const { t, i18n, tLanguageNameFromLanguageCode } = useTranslationService();

  function setLanguage(language: string) {
    i18n.changeLanguage(language);
  }

  return (
    <Select
      labelId="langauge-select-label"
      id="langauge-simple-select"
      value={i18n.language}
      label={t("language")}
      onChange={(e) => setLanguage(e.target.value)}
    >
      {LANGUAGES &&
        LANGUAGES.sort((a, b) => {
          return compareStrings(a.value, b.value);
        }).map((o) => (
          <MenuItem key={o.value} value={o.value}>
            {tLanguageNameFromLanguageCode(o.value) + " (" + o.value + ")"}
          </MenuItem>
        ))}
    </Select>
  );
}
