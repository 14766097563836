import { Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { isDebugInfo } from "../utils/AppUtils";
import { loadImageFromUrlIfPossible } from "../utils/Utils";
import { useUserService } from "../services/UserService";
import SmartTooltip from "../components/others/SmartTooltip";
import GenericInfoComponent from "./../components/others/GenericInfoComponent";
import { StyledContent } from "./../components/styles/styled.Content";
import { t } from "i18next";

function AccountInfoPage() {
  const { user } = useUserService();
  // const [image, setImage] = useState<null | HTMLImageElement>(null);
  const [imageComponent, setImageComponent] =
    useState<null | React.JSX.Element>(null);

  useEffect(() => {
    function imageCallback(imageData: HTMLImageElement | null): void {
      if (imageData != null) {
        // setImage(imageData);
        setImageComponent(
          <SmartTooltip title={t("image")}>
            <img src={imageData.src} alt="" />
          </SmartTooltip>
        );
      }
    }
    if (user != null) {
      const pictureUrl = user.picture;
      if (pictureUrl != null) {
        if (!imageComponent) {
          loadImageFromUrlIfPossible(pictureUrl, imageCallback);
        }
      }
    }
  }, [imageComponent, user]);

  return (
    <StyledContent>
      <Stack
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
      >
        {imageComponent}
        {user && user.nickname && (
          <SmartTooltip title={t("nickname")}>
            <Typography variant="h3">{user.nickname}</Typography>
          </SmartTooltip>
        )}
        {user && user.name && (
          <SmartTooltip title={t("name")}>
            <Typography variant="h5">{user.name}</Typography>
          </SmartTooltip>
        )}
        {user && user.email && (
          <SmartTooltip title={t("mail-address")}>
            <Typography variant="h5">{user.email}</Typography>
          </SmartTooltip>
        )}
        {user && user.email && (
          <SmartTooltip title={t("mail-address")}>
            <Typography variant="h6">{user.sub}</Typography>
          </SmartTooltip>
        )}
      </Stack>
      {isDebugInfo() && <GenericInfoComponent dataObject={user} open={false} />}
    </StyledContent>
  );
}

export default AccountInfoPage;
