import { Card, CardContent, CardHeader, Stack } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useGetPlacesFromPlacelist } from "../../services/PlacelistService";
import { IPlacelist } from "../../stuff/AppModelTypes";
import { sxForCard } from "../../stuff/StyleConstants";
import SmartAccordionLazy from "../accordion/SmartAccordionLazy";
import { PlacelistInfo } from "../info/PlacelistInfo";
import { CountriesMapForPlaces } from "../maps/CountriesMapForPlaces";
import SmartMap from "../maps/SmartMap";
import { MarkerVisualizations } from "../maps/SvgUtils";
import { SmartPadding } from "../others/SmartPadding";
import { TitleLink } from "../others/TitleLink";
import PlacesList from "../places/PlacesList";

interface IProps {
  placelist: IPlacelist;
  showPlaces?: boolean;
}

function PlacelistSmall({
  placelist,
  showPlaces = true,
}: IProps): JSX.Element | null {
  const { t } = useTranslation();
  const placesFromPlaceList = useGetPlacesFromPlacelist(placelist);

  // useMemo(() => {
  //   if

  //   {
  //     return placelist.Places;
  //   } else {
  //     return [];
  //   }
  // }, [placelist]);
  // const placesCompleted: any[] = usePlacesService2(placesFromPlaceList);

  // const [placesCompleted, setPlacsCompleted] = useState();

  // const placesCompleted = useMemo(() => {
  //   if (
  //     placelist == null ||
  //     placelist.Places == null ||
  //     placelist.Places.length > 0
  //   ) {
  //     return usePlacesService2(placelist?.Places);
  //   } else {
  //   }
  // }, [placelist]);
  //  const placesCompleted =useMemo(()=>{
  //   if (placelist==null || placelist.Places==null || placelist.Places.length>0) {
  //     return usePlacesService2(placelist?.Places)},[placelist]);
  //   }
  // })

  // useEffect(() => {
  //   const places = placelist.places;
  //   const completed = usePlacesService2(places);
  //   setPlaces;
  // }, []);

  // const places = placelist.Places;
  // if (placesCompleted != null) {
  //   // subheaderText = "Placelist mit " + places.length + " Plätzen";
  // }

  const places = placesFromPlaceList;
  const placeCount = places.length;
  let placesHeaderText = t("places");
  if (places) {
    if (placeCount === 0) {
      placesHeaderText = t("no-places");
    } else if (placeCount === 1) {
      placesHeaderText = t("one-place");
    } else if (placeCount > 1) {
      placesHeaderText = placeCount + " " + t("places");
    }
  }
  let subheaderText = placesHeaderText;

  let theMap = null;
  if (places != null && places.length != null && places.length > 0) {
    let mapHeaderText = t("map");
    theMap = (
      <SmartAccordionLazy
        header={mapHeaderText}
        childrenCallback={function (): JSX.Element | JSX.Element[] {
          return <SmartMap places={places} />;
        }}
      />
    );
  }

  let thePlaces = null;
  if (places) {
    thePlaces = (
      <SmartAccordionLazy
        header={placesHeaderText}
        disabled={placeCount < 1}
        childrenCallback={function (): JSX.Element | JSX.Element[] {
          return (
            <PlacesList
              key="place_list"
              places={places}
              parent={placelist}
              infoVisibility="collapsed"
            />
          );
        }}
      />
    );
  }
  // const { onePlacelistActions } = usePlacelistActions(undefined, placelist);
  // const actionBar = (
  //   <SmartActionBar actions={onePlacelistActions} showLabels={false} />
  // );

  const titleText = placelist.Name != null ? placelist.Name : `<?>`;

  const cardHeader = (
    <Stack direction="column">
      <TitleLink text={titleText} href={"/placelist/" + placelist.Id} />
      {/* </CardActionArea> */}
    </Stack>
  );

  const ret = (
    <Card raised={true} elevation={5} id={placelist.Id} sx={sxForCard}>
      {/* <CardActionArea
        component="span"
        onClick={(event) => {
          navigate("/placelist/" + placelist.Id);
          event.stopPropagation();
        }}
      > */}
      <CardHeader
        title={cardHeader}
        subheader={subheaderText}
        sx={{ pb: 0 }}
        avatar={
          <CountriesMapForPlaces
            width="75px"
            height="75px"
            places={places}
            markerVisualization={MarkerVisualizations.dot}
            disableMarkerClustering={true}
          />
        }
      />
      {showPlaces && (
        <CardContent>
          <PlacelistInfo placelist={placelist} />
          <SmartPadding />
          {theMap}
          <SmartPadding />
          {thePlaces}
          {/* {isDebugInfo() ? <GenericInfoComponent key="generic" dataObject={placelist} /> : null} */}
        </CardContent>
      )}
      {/* <SmartCardActions>{actionBar}</SmartCardActions> */}
      {/* </CardActionArea> */}
    </Card>
  );
  return ret;
}

export default PlacelistSmall;
