import React from "react";
import { useGetScrapeLinksFilteredQuery } from "../../api/PlacesApiSlice";
import ScrapeLinkComponent from "./ScrapeLinkComponent";
import { SmartQueryList } from "../lists/SmartQueryList";

interface IProps {
  queryArguments: any;
  pageSize?: number;
  fromLatiude?: number;
  fromLongitude?: number;
  infoVisibility?: "expanded" | "collapsed" | "hidden";
  updateCount?: (count: null | number) => void;
}

export default function ScrapeLinksResultList({
  queryArguments,
  // pageSize,
  // fromLatiude,
  // fromLongitude,
  // infoVisibility,
  updateCount,
}: IProps): JSX.Element | null {
  function renderComponent(element: any) {
    return (
      <ScrapeLinkComponent key={element.Id} scrapeLinkDataObject={element} />
    );
  }
  const query = useGetScrapeLinksFilteredQuery;

  return queryArguments != null ? (
    <SmartQueryList
      queryArguments={queryArguments}
      query={query}
      updateCount={updateCount}
      renderObject={renderComponent}
    />
  ) : (
    <p>loading...</p>
  );
}
