import React from "react";
import { IPlace } from "../../stuff/AppModelTypes";
import LeafletImage from "./LeafletImage";
import { isLocationInaccurateForPlace } from "../info/NavigationUtils";

const DEFAULT_SIZE = "300px";
const ZOOM_FACTOR_EXACT = 17;
const ZOOM_FACTOR_INACCURATE = 15;

interface IProps {
  place: IPlace;
  width?: string;
  height?: string;
}

export function MapImageForPlace({
  place,
  width,
  height,
}: IProps): JSX.Element | null {
  ({ width, height } = setSmartDefaults(width, height));
  const lat = place.Latitude;
  const lon = place.Longitude;
  const zoomFactor = isLocationInaccurateForPlace(place)
    ? ZOOM_FACTOR_INACCURATE
    : ZOOM_FACTOR_EXACT;

  let ret = null;
  if (lat != null && lon != null) {
    ret = (
      <LeafletImage
        longitude={lon}
        latitude={lat}
        zoom={zoomFactor}
        width={width}
        height={height}
        showCenterMarker={false}
        // showDebug={true}
      />
    );
  }
  return ret;
}

// ---------------------------------

function setSmartDefaults(
  width: string | undefined,
  height: string | undefined
): { width: string; height: string } {
  let ret = { width: DEFAULT_SIZE, height: DEFAULT_SIZE };
  if (width == null && height == null) {
    //ret = { width: DEFAULT_SIZE, height: DEFAULT_SIZE };
  } else {
    if (width != null) {
      ret.width = width;
      if (height == null) {
        ret.height = width;
      }
    }
    if (height != null) {
      ret.height = height;
      if (width == null) {
        ret.width = height;
      }
    }
  }
  return ret;
}
