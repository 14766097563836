import { useNavigate } from "react-router-dom";

function useAppService() {
  const navigate = useNavigate();
  const pathname = window.location.pathname;
  const ret = { navigate, pathname };
  return ret;
}

export default useAppService;
