import React from "react";
import { FeatureConst } from "../../../api/FeaturesConstants";
import useTranslationService from "../../../services/TranslationService";
import { SmartChip } from "../../chip/SmartChip";

type Props = {
  featureConst: FeatureConst;
  available?: boolean;
};

export function GenericFeatureChip({ featureConst, available = true }: Props) {
  const { tCapitalize } = useTranslationService();
  const text = tCapitalize(featureConst.translationKey);
  let tooltip = text;
  let icon = null;
  if (featureConst.icon != null) {
    icon = React.createElement(featureConst.icon);
  }
  const color = available ? "success" : "warning";
  return (
    <SmartChip
      color={color}
      icon={icon}
      text={text}
      tooltip={tooltip}
      negative={!available}
    />
  );
}
