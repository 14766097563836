import { ZoomInMapTwoTone } from "@mui/icons-material";
import React from "react";
import useTranslationService from "../../services/TranslationService";
import { IPlace } from "../../stuff/AppModelTypes";
import { SmartChip } from "../chip/SmartChip";

type Props = {
  place: IPlace;
};

export function MapChip({ place }: Props) {
  const { t, tCapitalize } = useTranslationService();

  const href = "/map/" + place.Id;

  const chip = (
    <SmartChip
      icon={<ZoomInMapTwoTone />}
      text={tCapitalize("map")}
      href={href != null ? href : undefined}
      target="_self"
      tooltip={t("show-on-the-map")}
    />
  );

  return chip;
}
