import React from "react";
import { useGetPlacelistsQuery } from "../../api/PlacesApiSlice";
import { SmartQueryList } from "../lists/SmartQueryList";
import PlacelistSmall from "./PlacelistSmall";

interface IProps {
  queryArguments: any;
  pageSize?: number;
  infoVisibility?: "expanded" | "collapsed" | "hidden";
  updateCount?: (count: null | number) => void;
}

export default function PlacelistsResultList({
  queryArguments,
  pageSize,
  infoVisibility,
  updateCount,
}: IProps): JSX.Element | null {
  function renderComponent(element: any) {
    return <PlacelistSmall placelist={element} />;
  }
  const query = useGetPlacelistsQuery;

  return queryArguments != null ? (
    <SmartQueryList
      queryArguments={queryArguments}
      query={query}
      updateCount={updateCount}
      renderObject={renderComponent}
    />
  ) : (
    <p>loading...</p>
  );
}
