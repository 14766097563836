import React from "react";
import { WASHING_MACHINE } from "../../../api/FeaturesConstants";
import { FeatureChip, FeatureChipSubtypeProps } from "./FeatureChip";

export function WashingMachineChip({
  feature,
  available,
}: FeatureChipSubtypeProps) {
  return (
    <FeatureChip
      feature={feature}
      featureConst={WASHING_MACHINE}
      available={available}
    />
  );
}
