import React from "react";
import { FRESH_WATER_SUPPLY } from "../../../api/FeaturesConstants";
import { FeatureChip, FeatureChipSubtypeProps } from "./FeatureChip";

export function FreshWaterSupplyChip({
  feature,
  available,
}: FeatureChipSubtypeProps) {
  return (
    <FeatureChip
      feature={feature}
      featureConst={FRESH_WATER_SUPPLY}
      available={available}
    />
  );
}
