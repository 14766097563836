import React from "react";
import PlaceSmall from "../place/PlaceSmall";
import PlaceSmallFromId from "../place/PlaceSmallFromId";
import { IPlace } from "../../stuff/AppModelTypes";

type RenderProps = {
  fromLatiude?: number;
  fromLongitude?: number;
  infoVisibility?: "expanded" | "collapsed" | "hidden";
  parent?: any;
};

export function createPlaceComponent(p: IPlace, renderProps: RenderProps) {
  const fromLatiude = renderProps?.fromLatiude;
  const fromLongitude = renderProps?.fromLongitude;
  const infoVisibility = renderProps?.infoVisibility;
  const parent = renderProps?.parent;

  if (p.Name != null) {
    return (
      <PlaceSmall
        key={"PlaceSmall_" + p.Id}
        placeObject={p}
        parent={parent}
        enableFlyTo={true}
        fromLatiude={fromLatiude}
        fromLongitude={fromLongitude}
        infoVisibility={infoVisibility}
      />
    );
  } else {
    return (
      <PlaceSmallFromId
        key={"PlaceSmallFromId_" + p.Id}
        placeId={p.Id}
        parent={parent}
        enableFlyTo={true}
        fromLatiude={fromLatiude}
        fromLongitude={fromLongitude}
        infoVisibility={infoVisibility}
      />
    );
  }
}
