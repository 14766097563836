import React, { Suspense } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { VisibleE } from "../stuff/AppConstants";
import useMenuService from "../services/MenuService";
import Kachel from "./Kachel";
import { StyledContent } from "./styles/styled.Content";

const StyledKachelmenu = styled(StyledContent)`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
  flex-grow: 1;
  flex-shrink: 0;
  gap: 10px;
  margin: 10px;
  @media only screen and (min-width: 432px) {
    flex-direction: row;
    justify-content: left;
    flex-wrap: wrap;
    align-items: stretch;
    align-content: flex-start;
    flex-grow: 1;
    flex-shrink: 0;
  }
`;

function Kachelmenu() {
  const { t, i18n } = useTranslation();
  const { kachelMenuModel } = useMenuService();

  function _createKachel(
    x: {
      [x: string]: string | undefined;
      kachel: VisibleE;
      link: string;
      icon: any;
      nameKey: string;
      tooltipKey: string | undefined;
    },
    index: number
  ) {
    const link = "/" + x.link;
    const ri = x.icon;
    const text = i18n.format(t(x.nameKey), "capitalize");
    const key = x.name + "-" + index;
    const ret = (
      <Kachel
        key={key}
        text={text}
        icon={ri}
        image={x.image}
        tooltip={x.tooltipKey && t(x.tooltipKey)}
        href={link}
      />
    );
    return ret;
  }

  const list =
    kachelMenuModel != null
      ? kachelMenuModel.map((x: any, index: number) => _createKachel(x, index))
      : null;
  const newLocal =
    list != null ? <StyledKachelmenu>{list}</StyledKachelmenu> : null;
  return <Suspense fallback={<h1>Loading menu...</h1>}>{newLocal}</Suspense>;
}

export default Kachelmenu;
