import { Card, Stack } from "@mui/material";
import React from "react";
import { useCheckIfPlaceIsDeleted } from "../../services/PlaceService";
import { IPlace } from "../../stuff/AppModelTypes";
import { stripDomainFromUrl } from "../../utils/Utils";
import SmartLink from "../others/SmartLink";

type IProps = {
  placeObject: IPlace;
  infoVisibility?: "expanded" | "collapsed" | "hidden";
};

function PlaceSmallSimple({
  placeObject,
  infoVisibility = "collapsed",
}: IProps) {
  // const { tCapitalize } = useTranslationService();
  const placeId = placeObject.Id;
  const isPlaceDeleted = useCheckIfPlaceIsDeleted(placeObject);

  let providerInfo = null;
  const scrapeLinks = placeObject.ScrapeLinks;
  if (!isPlaceDeleted && scrapeLinks != null) {
    providerInfo = scrapeLinks.map((sl) => {
      if (sl == null) {
        return null;
      }
      // return <ProviderChip key={"provider_" + sl.Url} url={sl.Url} />;
      return (
        <SmartLink href={sl.Url} variant="caption">
          {stripDomainFromUrl(sl.Url)}
        </SmartLink>
      );
    });
  }

  let ret = null;
  if (placeObject) {
    const placeInfo =
      infoVisibility === "hidden" ? null : (
        // <CardContent sx={{ pb: 0 }}>
        <Stack
          key="providers_stack"
          direction="row"
          justifyContent="center"
          alignItems="center"
          // spacing={"4pt"}
          // flexWrap="wrap"
          // useFlexGap
        >
          {/* <PlaceInfo place={placeObject} /> */}
          {providerInfo}
        </Stack>
        // </CardContent>
      );

    const titleText =
      placeObject.Name != null ? placeObject.Name : "<" + placeId + ">";
    const href = "/place/" + placeId;

    ret = (
      <Card raised={true} elevation={5} sx={{ p: 1 }}>
        {/* <CardContent> */}
        <Stack direction="column">
          <SmartLink internalLink={true} href={href} variant="body1">
            {titleText}
          </SmartLink>
          {placeInfo}
        </Stack>
        {/* </CardContent> */}
      </Card>
      // <Card
      //   raised={true}
      //   elevation={5}
      //   id={placeId}
      //   // sx={isPlaceDeleted ? sxForCardDeleted : sxForCard}
      // >
      //   <CardHeader
      //     title={cardHeader}
      //     // titleTypographyProps={{
      //     //   variant: "h6",
      //     // }}
      //     // sx={{ pb: 0 }}
      //     // subheader={subheaderText}
      //   />
      //   {cardContent}
      //   {/* <SmartCardActions>{actionBar}</SmartCardActions> */}
      // </Card>
    );
  }
  return ret;
}

export default PlaceSmallSimple;
