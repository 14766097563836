import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import { i18nextPlugin } from "translation-check";
import { z } from "zod";
import { zodI18nMap } from "zod-i18n-map";
import { capitalizeAllWords } from "./utils/Utils";

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  .use(i18nextPlugin)
  // .use(
  //   resourcesToBackend((language: string, namespace: string) => {
  //     const str = "./locales/" + language + "/" + namespace + ".json";
  //     // const str = "zod-i18n-map/locales/" + language + "/zod.json";
  //     import(str);
  //   })
  // )
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: "en",
    debug: false,
    // resources: {
    //   ja: { zod: translation },
    // },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
      format: function (value, format, lng) {
        if (format === "uppercase") return value.toUpperCase();
        if (format === "lowercase") return value.toLowerCase();
        if (format === "capitalizeall") return capitalizeAllWords(value);
        if (format === "capitalize")
          return `${value.substr(0, 1).toUpperCase()}${value.substr(1)}`;
        return value;
      },
    },
    // partialBundledLanguages: true,
  });

z.setErrorMap(zodI18nMap);

export { z };

export default i18n;
