import { skipToken } from "@reduxjs/toolkit/dist/query";
import React, { useEffect, useState } from "react";
import { equalObject } from "../../utils/Utils";
import SmartMap from "./SmartMap";

type Props = {
  query: any; // query function
  queryArguments: any;
  maxNumber?: number;
  updateCount?: (count: null | number) => void;
};

export function SmartQueryMap({
  query,
  queryArguments,
  maxNumber = 100,
  updateCount,
}: Props) {
  const [queryArgsInternal, setQueryArgsInternal] = useState<any>();
  const [model, setModel] = useState<any[]>([]);
  const [sentCount, setSentCount] = useState<number>();
  const { data } = query(queryArgsInternal ?? skipToken);

  useEffect(() => {
    const newObj = { ...queryArguments };
    newObj.size = maxNumber;
    if (!equalObject(queryArgsInternal, newObj)) {
      setQueryArgsInternal(newObj);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryArguments]);

  // adds the new records to the model state array
  useEffect(() => {
    if (data != null && data.records != null) {
      const newModel = data.records;
      setModel(newModel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (data != null && data.results != null) {
      const newCount = data.results;
      if (updateCount != null && newCount !== sentCount) {
        updateCount(newCount);
        setSentCount(newCount);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <SmartMap places={model} />
    </>
  );
}
