import React from "react";
import SmartAttribute from "../../others/SmartAttribute";
import GridContainer from "../../containers/GridContainer";

interface IProps {
  elements: any[];
  renderProps?: any;
  renderComponent: any;
}

export default function GenericList({
  elements,
  renderProps = {},
  renderComponent = renderComponentFallback,
}: IProps): JSX.Element | null {
  const ret = (
    <GridContainer
      items={elements}
      renderProps={renderProps}
      renderItem={renderComponent}
    />
  );
  return ret;
}

function renderComponentFallback(element: any, index: number) {
  return <SmartAttribute key={index} label={"#" + index} value={element} />;
}
