import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React from "react";
import GeoSearchComponent from "./search/GeoSearchComponent";

interface IDialogParams {
  onClose: any;
  open: boolean;
}

function SearchLocationDialog(p: IDialogParams) {
  const [selected, setSelected] = React.useState<any>(null);

  function handleClose() {
    p.onClose(null);
  }
  function handleOk() {
    p.onClose(selected);
  }
  function handleGeoSelect(p: any) {
    setSelected(p);
  }

  return (
    <div>
      <Dialog onClose={p.onClose} open={p.open} scroll="paper" fullScreen>
        <DialogTitle>Ort suchen</DialogTitle>
        <DialogContent dividers>
          <GeoSearchComponent onSelect={handleGeoSelect} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Abbrechen</Button>
          <Button disabled={selected == null} onClick={handleOk}>
            Navigieren
          </Button>
        </DialogActions>{" "}
      </Dialog>
    </div>
  );
}

export default SearchLocationDialog;
