import { Button, Link, TypographyOwnProps } from "@mui/material";
import React, { ReactNode } from "react";
import SmartTooltip from "./SmartTooltip";

interface IProps {
  href: string;
  children?: ReactNode;
  nowrap?: boolean;
  internalLink?: boolean;
  showAsButton?: boolean;
  variant?: TypographyOwnProps["variant"];
}

function SmartLink({
  href,
  children = "Link",
  nowrap,
  internalLink = false,
  showAsButton = false,
  variant = "inherit",
}: IProps): JSX.Element | null {
  const target = internalLink ? "_self" : "_blank";
  const theLink = showAsButton ? (
    <Button href={href} target={target} variant="outlined">
      {children}
    </Button>
  ) : (
    <Link
      noWrap={nowrap}
      href={href}
      target={target}
      rel="noopener"
      variant={variant}
    >
      {children}
    </Link>
  );

  return <SmartTooltip title={href}>{theLink}</SmartTooltip>;
}

export default SmartLink;
