import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import SmartAccordionLazy from "../components/accordion/SmartAccordionLazy";
import { PlacesResultAccordion } from "../components/places/PlacesResultAccordion";
import { ScrapeLinksQueryListAccordion } from "../components/scrape_link/ScrapeLinksQueryListAccordion";
import useTranslationService from "../services/TranslationService";
import { useUserService } from "../services/UserService";
import {
  createIsoStringSecondsBeforeNow,
  fromDaysToSeconds,
} from "../utils/Utils";

const SECONDS_ARRAY = [10, 30, 60, 300, 600, 1800, 3600, 3600 * 6, 3600 * 12];
const DAYS_ARRAY = [1, 2, 3, 4, 5, 10, 25, 50, 100, 250, 500, 1000];
// const DAYS_ARRAY = [4];

type ModelElement = {
  headerTextId: string;
  childrenCallback: () => any;
  checkVisibility?: () => boolean;
};

export function StatisticsForSuperuserPage() {
  const { t, fromMilisecondsToTimeString } = useTranslationService();
  const { isSuperuser } = useUserService();
  const [listModel, setListModel] = useState<ModelElement[] | undefined>(
    undefined
  );

  useEffect(() => {
    if (isSuperuser == null || t == null) {
      return;
    }
    function createForUpdatesdSinceDays(days: number) {
      const seconds = fromDaysToSeconds(days);
      const iso = createIsoStringSecondsBeforeNow(seconds);
      const time = fromMilisecondsToTimeString(seconds * 1000);
      return (
        <PlacesResultAccordion
          key={"PlacesResultAccordion-updated-places-" + days}
          header={t("updated-places-from-the-last-time", { time: time })}
          queryArguments={{
            fromUpdatedAt: iso,
          }}
        />
      );
    }
    function createForUpdatesdSinceSeconds(seconds: number) {
      const iso = createIsoStringSecondsBeforeNow(seconds);
      const time = fromMilisecondsToTimeString(seconds * 1000);
      return (
        <PlacesResultAccordion
          key={"PlacesResultAccordion-updated-places-" + seconds}
          header={t("updated-places-from-the-last-time", { time: time })}
          queryArguments={{
            fromUpdatedAt: iso,
          }}
        />
      );
    }
    function createForPlacesCreatedSinceDays(days: number) {
      const seconds = fromDaysToSeconds(days);
      const iso = createIsoStringSecondsBeforeNow(seconds);
      const time = fromMilisecondsToTimeString(seconds * 1000);
      return (
        <PlacesResultAccordion
          key={"PlacesResultAccordion-new-places-" + days}
          header={t("new-places-from-the-last-time", { time: time })}
          queryArguments={{
            fromCreatedAt: iso,
          }}
        />
      );
    }
    function createForScrapeLinksCreatedSinceDays(days: number) {
      const seconds = fromDaysToSeconds(days);
      const key = "ScrapeLinksQueryListAccordion-days-" + days;
      const iso = createIsoStringSecondsBeforeNow(seconds);
      const time = fromMilisecondsToTimeString(seconds * 1000);
      return (
        <ScrapeLinksQueryListAccordion
          key={key}
          header={t("new-scrape-links-from-the-last-time", { time: time })}
          queryArguments={{
            fromCreatedAt: iso,
          }}
        />
      );
    }
    function createForScrapeLinksUpdatedSinceSeconds(seconds: number) {
      const iso = createIsoStringSecondsBeforeNow(seconds);
      const key =
        "ScrapeLinksQueryListAccordion-updated-scrape-links-" + seconds;
      const time = fromMilisecondsToTimeString(seconds * 1000);
      return (
        <ScrapeLinksQueryListAccordion
          key={key}
          header={t("updated-scrape-links-from-the-last-time", {
            time: time,
          })}
          queryArguments={{
            fromUpdatedAt: iso,
          }}
        />
      );
    }
    function createForScrapeLinksCreatedSinceSeconds(seconds: number) {
      const iso = createIsoStringSecondsBeforeNow(seconds);
      const key = "ScrapeLinksQueryListAccordion-new-scrape-links-" + seconds;
      const time = fromMilisecondsToTimeString(seconds * 1000);
      return (
        <ScrapeLinksQueryListAccordion
          key={key}
          header={t("new-scrape-links-from-the-last-time", {
            time: time,
          })}
          queryArguments={{
            fromCreatedAt: iso,
          }}
        />
      );
    }
    function createForScrapeLinksUpdatedSinceDays(days: number) {
      const seconds = fromDaysToSeconds(days);
      const iso = createIsoStringSecondsBeforeNow(seconds);
      const key = "ScrapeLinksQueryListAccordion-updated-scrape-links-" + days;
      const time = fromMilisecondsToTimeString(seconds * 1000);
      return (
        <ScrapeLinksQueryListAccordion
          key={key}
          header={t("updated-scrape-links-from-the-last-time", {
            time: time,
          })}
          queryArguments={{
            fromUpdatedAt: iso,
          }}
        />
      );
    }
    function createContentForNewplaces() {
      const content = DAYS_ARRAY.map((days) => {
        return createForPlacesCreatedSinceDays(days);
      });
      return <>{content}</>;
    }
    function createContentForUpdatedPlaces() {
      const seconds_content = SECONDS_ARRAY.map((seconds) => {
        return createForUpdatesdSinceSeconds(seconds);
      });
      const days_content = DAYS_ARRAY.map((days) => {
        return createForUpdatesdSinceDays(days);
      });
      return (
        <>
          {seconds_content}
          {days_content}
        </>
      );
    }
    function createContentForOthers() {
      return (
        <>
          {createOneYear(2027)}
          {createOneYear(2026)}
          {createOneYear(2025)}
          {createOneYear(2024)}
          {createOneYear(2023)}
          {createOneYear(2022)}
          {createOneYear(2021)}
        </>
      );

      function createOneYear(year: number) {
        const yearAsText = year.toString();
        return (
          <PlacesResultAccordion
            key={"PlacesResultAccordion-new-places-date-" + yearAsText}
            header={t("new-places-date", { date: yearAsText })}
            queryArguments={{
              fromCreatedAt: yearAsText + "-01-01T00:00:00.000Z",
              toCreatedAt: yearAsText + "-12-31T23:59:59.999Z",
            }}
          />
        );
      }
    }
    function createContentForNewScrapeLinks() {
      const seconds_content = SECONDS_ARRAY.map((seconds) => {
        return createForScrapeLinksCreatedSinceSeconds(seconds);
      });
      const days_content = DAYS_ARRAY.map((days) => {
        return createForScrapeLinksCreatedSinceDays(days);
      });
      return (
        <>
          {seconds_content}
          {days_content}
        </>
      );
    }
    function createContentForUpdatedScrapeLinks() {
      const seconds_content = SECONDS_ARRAY.map((seconds) => {
        return createForScrapeLinksUpdatedSinceSeconds(seconds);
      });
      const days_content = DAYS_ARRAY.map((days) => {
        return createForScrapeLinksUpdatedSinceDays(days);
      });
      return (
        <>
          {seconds_content}
          {days_content}
        </>
      );
    }

    const newListModel: ModelElement[] = [
      {
        headerTextId: "new-places",
        childrenCallback: createContentForNewplaces,
      },
      {
        headerTextId: "updated-places",
        childrenCallback: createContentForUpdatedPlaces,
      },
      {
        headerTextId: "new-scrape-links",
        childrenCallback: createContentForNewScrapeLinks,
        checkVisibility: () => isSuperuser,
      },
      {
        headerTextId: "updated-scrape-links",
        childrenCallback: createContentForUpdatedScrapeLinks,
        checkVisibility: () => isSuperuser,
      },
      {
        headerTextId: "years",
        childrenCallback: createContentForOthers,
      },
    ];
    setListModel(newListModel);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuperuser, t]);

  // function createIsoStringDaysBeforeNow(days: number) {
  //   const d = new Date();
  //   d.setDate(d.getDate() - days);
  //   const ret = d.toISOString();
  //   return ret;
  // }
  function renderComponent(object: ModelElement) {
    if (object.checkVisibility == null || object.checkVisibility()) {
      return (
        <SmartAccordionLazy
          key={"SmartAccordionLazy-" + object.headerTextId}
          header={t(object.headerTextId)}
          childrenCallback={object.childrenCallback}
        />
      );
    } else {
      return null;
    }
  }

  return listModel != null ? (
    // <Virtuoso
    //   useWindowScroll
    //   data={listModel}
    //   itemContent={(_, data) => {
    //     return renderComponent(data);
    //   }}
    // />
    <>
      {listModel.map((m: any) => {
        return renderComponent(m);
      })}
    </>
  ) : (
    <Typography>{t("loading")}</Typography>
  );
}
