import { isEqual, round } from "lodash";

export function getObjectFromSessionStorage(key: string) {
  const item = sessionStorage.getItem(key);
  if (item != null) {
    return parseJsonIfNeeded(item);
  }
  return null;
}

export function getObjectFromLocalStorage(key: string) {
  const item = localStorage.getItem(key);
  if (item != null) {
    return parseJsonIfNeeded(item);
  }
  return null;
}

export function isJSONString(string: string) {
  try {
    const parsed = JSON.parse(string);
    if (parsed && typeof parsed === "object") {
      return true;
    }
  } catch {
    return false;
  }
  return false;
}

export function isNullOrUndefined(value: any) {
  return value === null || value === undefined;
}

export function isString(value: any) {
  return typeof value === "string";
}

export function isArray(value: any) {
  return Array.isArray(value);
}

export function getArrayLength(value: any) {
  if (isArray(value)) {
    return value.length;
  }
  return 0;
}

export function isArrayEmpty(value: any) {
  return Array.isArray(value) && value.length < 1;
}

export function isArrayOfStrings(value: any[]) {
  if (isArray(value)) {
    return value.every((v: any) => typeof v === "string");
  }
  return false;
}

export function numberToMoney(number: number | bigint) {
  if (number == null) {
    return "-";
  }
  const money = new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",
  });
  const format = money.format(number);
  const ret = format.replace(",00", ",-");
  return ret;
}

export function isValidUrl(string: string) {
  const urlPattern = new RegExp(
    "^(https?:\\/\\/)?" + // validate protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // validate fragment locator
  const isPatternTestOk = urlPattern.test(string);
  if (!isPatternTestOk) {
    return false;
  }
  try {
    new URL(string);
    return true;
  } catch (err) {
    return false;
  }
}

//checks if a given URL is a valid image URL based on its file extension
export function isValidImageUrl(url: string) {
  return url.match(/\.(jpeg|jpg|gif|png|svg)$/) != null;
}

export function loadImageFromUrlIfPossible(
  imageUrl: string,
  callback: {
    (imageData: HTMLImageElement | null): void;
  }
) {
  if (isValidImageUrl(imageUrl)) {
    const imageData = new Image();
    imageData.onload = function () {
      callback(imageData);
    };
    imageData.onerror = function () {
      callback(null);
    };
    imageData.src = imageUrl;
  } else {
    callback(null);
  }
}

export function navigateToUrl(url: string) {
  var link = document.createElement("a");
  link.href = url;
  link.target = "_blank";
  link.click();
}

export function stripDomainFromUrl(url: string) {
  let ret = url;
  if (url.indexOf("//") !== -1) {
    const tArray = url.split("//");
    ret = tArray[1];
  }
  if (url.indexOf("www.") !== -1) {
    const tArray = url.split("www.");
    ret = tArray[1];
  }
  ret = ret.split("/")[0];
  return ret;
}

export function makeBetterUrl(url: string) {
  let ret = url;
  const DIVIDER = "//";
  const tArray = url.split(DIVIDER);
  const protocol = tArray[0];
  const mainPart = tArray[1];
  if (mainPart && mainPart.startsWith("park4night.com/lieu/")) {
    const newMainPart = mainPart.replace(
      "park4night.com/lieu/",
      "park4night.com/de/place/"
    );
    if (mainPart !== newMainPart) {
      ret = protocol + DIVIDER + newMainPart;
    }
  }
  return ret;
}

export function parseJsonIfNeeded(anything: any) {
  if (typeof anything !== "string") {
    return anything;
  }
  try {
    return JSON.parse(anything);
  } catch (error) {
    return anything;
  }
}
export function testJsonString(text: string) {
  if (typeof text !== "string") {
    return false;
  }
  try {
    JSON.parse(text);
    return true;
  } catch (error) {
    return false;
  }
}

export function gpsCoordsToDistanceInKm(
  lon1: number,
  lat1: number,
  lon2: number,
  lat2: number
) {
  const deltaLon = Math.abs(lon1 - lon2);
  const deltaLat = Math.abs(lat1 - lat2);
  const middleLat = (lat1 + lat2) / 2;
  const factorLat = 111.3;
  const factorLon = 111.3 * Math.cos((middleLat * Math.PI) / 180);
  const dx = factorLat * deltaLat;
  const dy = factorLon * deltaLon;
  return Math.sqrt(dx * dx + dy * dy);
}
export function boxAroundPoint(lon: number, lat: number, km: number) {
  const factorLon = 111.3 * Math.cos((lat * Math.PI) / 180);
  const factorLat = 111.3;
  const deltaLon = km / factorLon;
  const deltaLat = km / factorLat;
  const ret = {
    lon1: lon - deltaLon,
    lat1: lat - deltaLat,
    lon2: lon + deltaLon,
    lat2: lat + deltaLat,
  };
  return ret;
}

export function fromKmNumberToDistanceText(numberInKm: number) {
  if (numberInKm < 0) {
    return "negative Entfernung";
  }
  if (numberInKm < 1) {
    const format = new Intl.NumberFormat("de-DE", {
      style: "unit",
      unit: "meter",
      unitDisplay: "short",
    });
    const meters = numberInKm * 1000;
    const rounded = round(meters, 0);
    const ret = format.format(rounded);
    return ret;
  }
  if (numberInKm < 10) {
    const format = new Intl.NumberFormat("de-DE", {
      style: "unit",
      unit: "kilometer",
      unitDisplay: "short",
    });
    const rounded = round(numberInKm, 1);
    const ret = format.format(rounded);
    return ret;
  }
  const format = new Intl.NumberFormat("de-DE", {
    style: "unit",
    unit: "kilometer",
    unitDisplay: "short",
  });
  const rounded = round(numberInKm, 0);
  const ret = format.format(rounded);
  return ret;
}

export function capitalizeFirstLetter(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function equalContent(str1: string, str2: string): boolean {
  const clear1 = str1.toLowerCase().trim().normalize();
  const clear2 = str2.toLowerCase().trim().normalize();
  return clear1 === clear2;
}

export function equalObject(obj1: any, obj2: any): boolean {
  return isEqual(obj1, obj2);
}

export function capitalizeAllWords(str: string) {
  let capitalizedStr = "";
  let words = str.split(" ");
  for (let i = 0; i < words.length; i++) {
    let word = words[i].toLowerCase();
    capitalizedStr += word.charAt(0).toUpperCase() + word.slice(1) + " ";
  }
  return capitalizedStr.trim();
}

export function compareStrings(a: string | undefined, b: string | undefined) {
  if (a != null && b != null) {
    let x = a.toLowerCase();
    let y = b.toLowerCase();
    if (x < y) {
      return -1;
    }
    if (x > y) {
      return 1;
    }
  }
  return 0;
}
export function concatArrays(a: any[], b: any) {
  const ret = [...a, ...b];
  return ret;
}

export function createIsoStringSecondsBeforeNow(s: number) {
  const d = new Date();
  d.setTime(d.getTime() - s * 1000);
  const ret = d.toISOString();
  return ret;
}

export function fromDaysToSeconds(days: number) {
  return days * 24 * 60 * 60;
}
