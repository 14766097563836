import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { isNullOrUndefined } from "../utils/Utils";
import { PlacelistsAccess } from "./DbConstants"
import { updateArray } from "../utils/ArrayUtils2";
import { createListString, createFilterString } from "./ApiUtil";

export const placesApi = createApi({
  reducerPath: "placesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://placesdata.photobrandy.de/api.php/records/",
  }),
  tagTypes: ['Place', 'ScrapeLink', 'UserData', 'Placelist', 'PlaceAttachment', 'Placelists_Places', 'Userdata_Places', 'Userdata_Placelists'],
  endpoints: (builder) => ({
    getPlace: builder.query({
      query: (placeId) => "Places?filter=Id,eq," + placeId,
      providesTags: (result, error, id) => [{ type: 'Place', id }],
    }),
    getPlaceJoined: builder.query({
      query: (placeId) => "Places?filter=Id,eq," + placeId + "&join=ScrapeLinks&join=PlaceAttachments&join=Placelists&join=placelists_places,Placelists",
      providesTags: ['Place'],
    }),
    getPlacesJoinFiltered: builder.query({
      query: (args) => {
        let ret = _getPlacesFilteredQuery(args);
        ret = ret + "&join=ScrapeLinks&join=PlaceAttachments&join=Placelists&join=placelists_places,Placelists";
        return ret;
      },
      providesTags: ['Place'],
    }),
    getPlacesFiltered: builder.query({
      query: (args) => {
        let ret = _getPlacesFilteredQuery(args);
        return ret;
      },
      providesTags: ['Place'],
    }),
    getPlacesFilteredAsync: builder.query({
      query: (args) => _getPlacesFilteredQuery(args),
      // Only have one cache entry because the arg always maps to one string
      serializeQueryArgs: (a) => {
        const queryArgs = { ...a.queryArgs };
        queryArgs.page = null;
        queryArgs.maxResults = null;
        const ret = _getPlacesFilteredQuery(queryArgs);
        return ret;
      },
      // Always merge incoming data to the cache entry
      merge: (currentCache, newItems, a) => {
        const currentRecords = currentCache.records
        const newRecords = newItems.records;
        // currentRecords.push(...newRecords);
        function _equalsPlaces(a, b) {
          const ret = (a.Id === b.Id);
          return ret;
        };
        updateArray(currentRecords, newRecords, _equalsPlaces, false);
      },
      // Refetch when the page arg changes
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg
      },
      providesTags: ['Place'],
    }),
    getScrapeLinks: builder.query({
      query: (placeId) => "ScrapeLinks?filter=PlaceId,eq," + placeId,
      providesTags: (result) =>
        result
          ? [
            ...result.records.map(({ id }) => ({ type: 'ScrapeLink', id })),
            { type: 'ScrapeLink', id: 'LIST' },
          ]
          : [{ type: 'ScrapeLink', id: 'LIST' }],
    }),
    getScrapeLinksFiltered: builder.query({
      query: (args) => _getScrapeLinksFilteredQuery(args),
      // query: (placeId) => "ScrapeLinks?filter=PlaceId,eq," + placeId,
      providesTags: (result) =>
        result
          ? [
            ...result.records.map(({ id }) => ({ type: 'ScrapeLink', id })),
            { type: 'ScrapeLink', id: 'LIST' },
          ]
          : [{ type: 'ScrapeLink', id: 'LIST' }],
    }),
    getScrapeLink: builder.query({
      query: (scrapeLinkId) => "ScrapeLinks?filter=Id,eq," + scrapeLinkId,
      providesTags: (result, error, id) => [{ type: 'ScrapeLink', id }],
    }),
    getAllCountries: builder.query({
      query: () => "Places?include=Land",
      transformResponse: (response) => {
        const all = response.records;
        const countries = [];
        all.forEach((el) => {
          if (el.Land && !countries.includes(el.Land)) {
            countries.push(el.Land);
          }
        });
        countries.sort(function (w1, w2) {
          return w1.localeCompare(w2);
        });
        return countries;
      }
    }),
    getUserData: builder.query({
      query: (userId) => "UserData?filter=UserId,eq," + userId,
      providesTags: ['UserData'],
    }),
    createUserData: builder.mutation({
      query: data => ({
        url: `/UserData`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['UserData'],
    }),
    updateUserData: builder.mutation({
      query: data => ({
        url: `/UserData/${data.records[0].UserId}`,
        method: 'PUT',
        body: data.records[0]
      }),
      // invalidatesTags: (result, error, id) => [{ type: 'UserData', id }],
      invalidatesTags: ['UserData'],
    }),
    getPlacelistsForUser: builder.query({
      query: (args) => _getPlacelistsFilteredQuery(args),
      //   providesTags: (result) =>
      //     result
      //       ? [
      //         ...result.records.map(({ id }) => ({ type: 'Placelist', id })),
      //         { type: 'Placelist', id: 'LIST' },
      //       ]
      //       : [{ type: 'Placelist', id: 'LIST' }],
      providesTags: ['UserData'],
    }),
    getUserdataForUserId: builder.query({
      query: (userId) => "UserData?filter=UserId,eq," + userId + "&join=Places,ScrapeLinks&join=Places,PlaceAttachments&join=Placelists,Places,ScrapeLinks&join=Placelists,Places,PlaceAttachments&join=userdata_places&join=userdata_placelists",
      // query: (args) => _getPlacelistsFilteredQuery(args),
      //   providesTags: (result) =>
      //     result
      //       ? [
      //         ...result.records.map(({ id }) => ({ type: 'Placelist', id })),
      //         { type: 'Placelist', id: 'LIST' },
      //       ]
      //       : [{ type: 'Placelist', id: 'LIST' }],
      providesTags: ['UserData'],
    }),
    getPlacelists: builder.query({
      query: (args) => _getPlacelistsFilteredQuery(args),
      // query: (placelistId) => "Placelists" + "?join=Places,ScrapeLinks&join=Places,PlaceAttachments",
      // transformResponse: (response, meta, arg) => {
      //   if (response != null && response.records != null) {
      //     const placelists = response.records;
      //     for (let index = 0; index < placelists.length; index++) {
      //       const placelist = placelists[index];
      //       if (placelist.placelists_places != null) {
      //         const relations = placelist.placelists_places;
      //         for (let j = 0; j < relations.length; j++) {
      //           const rel = relations[j];
      //           rel.placelists_id = placelist;
      //         }
      //       }
      //     }
      //   }
      //   return response
      // },
      providesTags: [{ type: 'Placelist', id: 'LIST' }],
    }),
    getPlacelist: builder.query({
      query: (placelistId) => "Placelists?filter=Id,eq," + placelistId + "&join=Places,ScrapeLinks&join=Places,PlaceAttachments&join=Places,Placelists&join=placelists_places&join=Places,placelists_places",
      providesTags: (result, error, id) => [{ type: 'Placelist', id }],
    }),
    createPlacelist: builder.mutation({
      query: (data) => ({
        url: `/Placelists`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: [{ type: 'Placelist', id: 'LIST' }], refetchOnFocus: true,
    }),
    addPlaceToPlacelist: builder.mutation({
      query: (data) => ({
        url: `/placelists_places`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: [{ type: 'Placelists_Places', id: 'LIST' }, { type: 'UserData', id: 'LIST' }], refetchOnFocus: true,
    }),
    getRelationsUserdataToPlace: builder.query({
      query: (userdataId) => "userdata_places?filter=userdata_id,eq," + userdataId,
      // query: ({ userdataId, placeId }) => "userdata_places?filter=UserDataId,eq," + userdataId + "&filter=PlaceId,eq," + placeId,
      providesTags: [{ type: 'Userdata_Places', id: 'LIST' }, { type: 'UserData', id: 'LIST' }],
    }),
    getRelationsUserdataToPlacelist: builder.query({
      query: (args) => _getRelationsUserdataToPlacelistsFilteredQuery(args),
      providesTags: [{ type: 'Userdata_Placelists', id: 'LIST' }],
    }),
    getRelationsPlacelistsToPlacesForPlaceId: builder.query({
      query: (placeId) => _getRelationsPlacelistsToPlacesFilteredQuery({ places_id: placeId }),
      providesTags: [{ type: 'Placelists_Places', id: 'LIST' }],
    }),
    getRelationPlacelistsToPlacesForId: builder.query({
      query: (id) => "placelists_places?filter=Id,eq," + id + "&join=Placelists&join=Places",
      providesTags: (result, error, id) => [{ type: 'Placelists_Places', id: id }],
    }),
    getRelationUserDataToPlacesForId: builder.query({
      query: (id) => "userdata_places?filter=Id,eq," + id + "&join=UserData&join=Places",
      providesTags: (result, error, id) => [{ type: 'Userdata_Places', id: id }],
    }),
    getRelationUserDataToPlacelistsForId: builder.query({
      query: (id) => "userdata_placelists?filter=Id,eq," + id + "&join=UserData&join=Placelists",
      providesTags: (result, error, id) => [{ type: 'Userdata_Placelists', id: id }],
    }),
    getRelationsPlacelistsToPlacesForPlacelistId: builder.query({
      query: (placelistId) => _getRelationsPlacelistsToPlacesFilteredQuery({ placelists_id: placelistId }),
      providesTags: [{ type: 'Placelists_Places', id: 'LIST' }],
    }),
    addPlaceToUserdata: builder.mutation({
      query: (data) => ({
        url: `/userdata_places`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['UserData', { type: 'Userdata_Places', id: 'LIST' }, { type: 'UserData', id: 'LIST' }], refetchOnFocus: true,
    }),
    removePlaceFromUserdata: builder.mutation({
      query: (relationId) => ({
        url: `/userdata_places/` + relationId,
        method: 'DELETE',
      }),
      invalidatesTags: ['UserData', { type: 'Userdata_Places', id: 'LIST' }, { type: 'UserData', id: 'LIST' }], refetchOnFocus: true,
    }),
    updatePlacelistName: builder.mutation({
      query: ({ placelistId, newName }) => ({
        url: `/Placelists/` + placelistId,
        method: 'PUT',
        body: { 'Name': newName }
      }),
      invalidatesTags: ['Placelist'],
    }),
    deletePlacelist: builder.mutation({
      query: (placelistId) => ({
        url: `/Placelists/` + placelistId,
        method: 'DELETE',
      }),
      invalidatesTags: ['Placelist'],
    }),
    updatePlacelistPlaces: builder.mutation({
      query: ({ placelistId, places }) => ({
        url: `/Placelists/` + placelistId,
        method: 'PUT',
        body: { 'Places': places }
      }),
      invalidatesTags: ['Placeslist'],
    }),
    getAllPlaceAttachments: builder.query({
      query: () => "PlaceAttachments",
      providesTags: ['PlaceAttachment'],
    }),
    getPlaceAttachmentsForPlace: builder.query({
      query: (placeId) => "PlaceAttachments?filter=PlaceId,eq," + placeId,
      providesTags: ['PlaceAttachment'],
    }),
    createPlaceAttachment: builder.mutation({
      query: (data) => ({
        url: `/PlaceAttachments`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['PlaceAttachment'],
    }),
    updateRelationPlacelistPlaceText: builder.mutation({
      query: ({ relationId, newText }) => ({
        url: `/placelists_places/` + relationId,
        method: 'PUT',
        body: { 'Comment': newText }
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Placelists_Places', id: arg.relationId }, { type: 'Placelist', id: 'LIST' }, { type: 'UserData', id: 'LIST' }], refetchOnFocus: true,
    }),
  })
});

// --------------------------------------------------------------------

function _getPlacesFilteredQuery(props) {
  // let ret = "/Places?join=ScrapeLinks";
  let ret = "/Places";
  ret = ret + createFilterString(props); //if(props)
  return ret;
};

function _getScrapeLinksFilteredQuery(props) {
  // let ret = "/ScrapeLinks?join=Places";
  let ret = "/ScrapeLinks";
  ret = ret + "?order=updated_at,asc";
  if (props) {
    if (props.placeId != null) {
      ret = ret + "&filter=PlaceId,eq," + props.placeId;
    }
    if (props.searchString && props.searchString.length > 0) {
      ret = ret + "&filter=Url,cs," + props.searchString;
    }
    if (props.fromUpdatedAt) {
      ret = ret + "&filter=updated_at,ge," + props.fromUpdatedAt;
    }
    if (props.toUpdatedAt) {
      ret = ret + "&filter=updated_at,le," + props.toUpdatedAt;
    }
    if (props.fromCreatedAt) {
      ret = ret + "&filter=created_at,ge," + props.fromCreatedAt;
    }
    if (props.toCreatedAt) {
      ret = ret + "&filter=updated_at,le," + props.toCreatedAt;
    }
    if (props.fromLastScrape) {
      ret = ret + "&filter=LastScrape,ge," + props.fromLastScrape;
    }
    if (props.toLastScrape) {
      ret = ret + "&filter=LastScrape,le," + props.toLastScrape;
    }
    if (props.maxResults) {
      ret = ret + "&size=" + props.maxResults;
    }
    if (
      props.onlyWithData &&
      !(props.onlyWithData === undefined) &&
      props.onlyWithData === true
    ) {
      // eslint-disable-next-line no-useless-concat
      ret = ret + "&filter=Data,nis";
    }
    if (
      props.onlyWithStatus &&
      !(props.onlyWithStatus === undefined) &&
      props.onlyWithStatus === true
    ) {
      // eslint-disable-next-line no-useless-concat
      ret = ret + "&filter=Status,nis";
    }
    if (props.ids && props.ids.length > 0) {
      const idListString = createListString(props.ids);
      if (idListString && idListString.length > 0) {
        ret = ret + "&filter=Id,in," + idListString;
      }
    }
    if (props.excludeIds && props.excludeIds.length > 0) {
      const excludeIdListString = createListString(props.excludeIds);
      if (excludeIdListString && excludeIdListString.length > 0) {
        ret = ret + "&filter=Id,nin," + excludeIdListString;
      }
    }
    const page = props.page;
    if (!isNullOrUndefined(page) && page > 0) {
      ret = ret + "&page=" + page;
      const pageSize = props.pageSize;
      if (pageSize && pageSize > 0) {
        ret = ret + "," + pageSize;
      }
    }
  } //if(props)
  return ret;
};
function _getPlacelistsFilteredQuery(props) {
  let ret = "/Placelists";
  ret = ret + "?order=Name";
  if (props) {
    if (props.userId && props.userId.length > 0) {
      ret = ret + "&filter=Users,cs," + props.userId;
    }
    if (props.searchString && props.searchString.length > 0) {
      ret = ret + "&filter=Name,cs," + props.searchString;
    }
    if (props.showOnlyPublicPlacelists === true) {
      ret = ret + "&filter=Access,eq," + PlacelistsAccess.Public;
    }
    if (props.placeId) {
      ret = ret + "&filter=Places,cs," + props.placeId;
    }
    const page = props.page;
    if (!isNullOrUndefined(page) && page > 0) {
      ret = ret + "&page=" + page;
      const pageSize = props.pageSize;
      if (pageSize && pageSize > 0) {
        ret = ret + "," + pageSize;
      }
    }
  } //if(props)
  ret = ret + "&join=Places,ScrapeLinks&join=Places,PlaceAttachments&join=placelists_places,Places&join=Places,placelists_places,Placelists"
  return ret;
};
function _getRelationsUserdataToPlacelistsFilteredQuery(props) {
  let ret = "/userdata_placelists";
  ret = ret + "?order=Id";
  if (props) {
    if (props.userdata_id != null) {
      ret = ret + "&filter=userdata_id,eq," + props.userdata_id;
    }
    if (props.placelists_id != null) {
      ret = ret + "&filter=placelists_id,eq," + props.placelists_id;
    }
    if (props.role != null) {
      ret = ret + "&filter=Role,eq," + props.role;
    }
  } //if(props)
  ret = ret + "&join=Placelists&join=UserData"
  return ret;
};

function _getRelationsPlacelistsToPlacesFilteredQuery(props) {
  let ret = "/placelists_places";
  ret = ret + "?order=Id";
  if (props) {
    if (props.places_id != null) {
      ret = ret + "&filter=places_id,eq," + props.places_id + "&join=placelists_places,Placelists";
    }
    if (props.placelists_id != null) {
      ret = ret + "&filter=placelists_id,eq," + props.placelists_id + "&join=placelists_places,Places";
    }
  } //if(props)
  ret = ret + "&join=Placelists&join=Places";
  return ret;
};

export const {
  useGetRelationPlacelistsToPlacesForIdQuery,
  useGetRelationUserDataToPlacesForIdQuery,
  useGetRelationUserDataToPlacelistsForIdQuery,
  useGetRelationsUserdataToPlacelistQuery,
  useGetRelationsPlacelistsToPlacesForPlaceIdQuery,
  useGetRelationsPlacelistsToPlacesForPlacelistIdQuery,
  useCreatePlaceAttachmentMutation,
  useGetPlaceAttachmentsForPlaceQuery,
  useGetAllPlaceAttachmentsQuery,
  useCreatePlacelistMutation,
  useAddPlaceToPlacelistMutation,
  useDeletePlacelistMutation,
  useUpdatePlacelistNameMutation,
  useUpdatePlacelistPlacesMutation,
  useGetPlacelistQuery,
  useGetPlacelistsQuery,
  useGetPlacelistsForUserQuery,
  useGetUserdataForUserIdQuery,
  // useGetUserDataQuery,
  useCreateUserDataMutation,
  useUpdateUserDataMutation,
  useGetRelationsUserdataToPlaceQuery,
  useAddPlaceToUserdataMutation,
  useRemovePlaceFromUserdataMutation,
  useGetAllCountriesQuery,
  // useGetAllPlacesQuery,
  // useGetPlaceQuery,
  useGetPlaceJoinedQuery,
  // useGetPlacesFilteredQuery,
  useGetPlacesJoinFilteredQuery,
  // useGetPlacesFilteredAsyncQuery,
  useGetScrapeLinkQuery,
  useGetScrapeLinksQuery,
  useGetScrapeLinksFilteredQuery,
  useUpdateRelationPlacelistPlaceTextMutation
} = placesApi;

// -------------------------------------------------------

