import React from "react";
import { GRAY_WATER_DISPOSAL } from "../../../api/FeaturesConstants";
import { FeatureChip, FeatureChipSubtypeProps } from "./FeatureChip";

export function GrayWaterDisposalChip({
  feature,
  available,
}: FeatureChipSubtypeProps) {
  return (
    <FeatureChip
      feature={feature}
      featureConst={GRAY_WATER_DISPOSAL}
      available={available}
    />
  );
}
