import React from "react";
import { useGetScrapeLinksFilteredQuery } from "../../api/PlacesApiSlice.js";
import GenericQueryList from "../lists/GenericQueryList";
import ScrapeLinkComponent from "./ScrapeLinkComponent";

interface IProps {
  queryArguments: any;
  pageSize?: number;
  updateCount?: (count: null | number) => void;
}

function ScrapeLinksQueryList({
  queryArguments,
  updateCount,
}: IProps): JSX.Element | null {
  // const query = useGetScrapeLinksFilteredQuery(queryArguments ?? skipToken);
  const query = useGetScrapeLinksFilteredQuery;

  function renderComponent(element: any, index: number) {
    return <ScrapeLinkComponent key={index} scrapeLinkDataObject={element} />;
  }

  return (
    <GenericQueryList
      query={query}
      queryArguments={queryArguments}
      updateCount={updateCount}
      renderComponent={renderComponent}
      pageSize={5}
    />
  );
}

export default ScrapeLinksQueryList;
