import { FilterAltTwoTone } from "@mui/icons-material";
import { MenuItem, TextField, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { recallCountries, recallFeatures, recallProviders } from "../../services/SettingsService";
import useTranslationService from "../../services/TranslationService";
import {countChecked, createProvidersDecoration} from "../../services/InfoService";
import { isNullOrUndefined } from "../../utils/Utils";
import SmartActionBar from "../buttons/SmartActionBar";
import { FeatureSettingsForm } from "../settings/FeatureSettingsForm";
import { ProviderSettingsForm } from "../settings/ProviderSettingsForm";
import {
  MAX_PRICE_OPTIONS
} from "./PlacesConstants";
import PlacesResultList from "./PlacesResultList";

const SHOW_LABELS = true;

export function PlacesListWithFilter() {
  const { t } = useTranslationService();
  const isWideScreen = useRef(window.innerWidth >= 700);
  const [countries] = useState(recallCountries());
  const storedShowPlacesWithoutAttachment = localStorage.getItem(
    "showPlacesWithoutAttachment"
  );
  const [showPlacesWithoutAttachment] = useState(
    storedShowPlacesWithoutAttachment != null
      ? JSON.parse(storedShowPlacesWithoutAttachment)
      : true
  );
  const storedShowPlacesWithoutPriceInfo = localStorage.getItem(
    "showPlacesWithoutPriceInfo"
  );
  const [showPlacesWithoutPriceInfo] = useState(
    storedShowPlacesWithoutPriceInfo != null
      ? JSON.parse(storedShowPlacesWithoutPriceInfo)
      : true
  );
  const storedMaxPrice = localStorage.getItem("maxPrice");
  const [maxPrice, setMaxPrice] = useState(
    storedMaxPrice
      ? storedMaxPrice
      : MAX_PRICE_OPTIONS[MAX_PRICE_OPTIONS.length - 1].value
  );
  const [features, setFeatures] = useState(recallFeatures());
  const [providers, setProviders] = useState(recallProviders());
  const [searchString, setSearchString] = useState("");
  const [queryProps, setQueryProps] = useState({});

  useEffect(() => {
    localStorage.setItem("maxPrice", maxPrice);
    const props = {
      searchString: searchString,
      countries: countries,
      showPlacesWithoutAttachment,
      showPlacesWithoutPriceInfo,
      maxPrice: maxPrice,
      providers: providers,
      features: features,
      joinScrapeLinks: true,
      deleted: false,
    };
    setQueryProps(props);
  }, [
    searchString,
    showPlacesWithoutAttachment,
    showPlacesWithoutPriceInfo,
    maxPrice,
    providers,
    features,
    countries,
  ]);

  let list = <Typography>{t("loading")}</Typography>;
  if (!isNullOrUndefined(queryProps) && Object.keys(queryProps).length > 0) {
    list = <PlacesResultList queryArguments={queryProps} />;
  }

  function _createFeatureSettingsForm(props) {
    const content = <FeatureSettingsForm />;
    return { content };
  }

  function _createProviderSettingsForm(props) {
    const content = <ProviderSettingsForm />;
    return { content };
  }

  const actions = [
    <TextField
      key="TextField_search-in-name"
      id="outlined-basic"
      label={t("search-in-name")}
      value={searchString}
      type="search"
      onChange={(e) => setSearchString(e.target.value)}
    />,
    {
      text: t("maximum-price"),
      shortText: t("max-price"),
      showLabel: SHOW_LABELS,
      value: maxPrice,
      onMouse: (a) => {
        setMaxPrice(a.target.value);
      },
      tooltipPlacement: "top",
      children:
        MAX_PRICE_OPTIONS &&
        MAX_PRICE_OPTIONS.map((o) => (
          <MenuItem key={o.key} value={o.value}>
            {o.label}
          </MenuItem>
        )),
    },
    {
      text: t("filter-by-features"),
      shortText: t("features"),
      showLabel: SHOW_LABELS,
      tooltipPlacement: "top",
      children: <FilterAltTwoTone />,
      decoration: countChecked(features),
      dialogProps: {
        title: t("features"),
        onCreateContent: _createFeatureSettingsForm,
        onClose: () => {
          setFeatures(recallFeatures());
        },
      },
    },
    {
      text: t("filter-by-providers"),
      shortText: t("providers"),
      showLabel: SHOW_LABELS,
      tooltipPlacement: "top",
      children: <FilterAltTwoTone />,
      decoration: createProvidersDecoration(providers),
      dialogProps: {
        title: t("providers"),
        onCreateContent: _createProviderSettingsForm,
        onClose: () => {
          setProviders(recallProviders());
        },
      },
    },
  ];
  const toolbar = (
    <SmartActionBar
      actions={actions}
      accordionOpen={isWideScreen.current}
      showLabels={true}
    />
  );

  return (
    <>
      {toolbar}
      {list}
    </>
  );
}

//--------------------------------------------------

