import { Stack, Typography } from "@mui/material";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { TitleContext } from "../../TitleContext";
import useTranslationService from "../../services/TranslationService";
import { IPlace } from "../../stuff/AppModelTypes";
import { isNullOrUndefined } from "../../utils/Utils";
import { PlaceInfo } from "../info/PlaceInfo";
import { CountriesMapForPlaces } from "../maps/CountriesMapForPlaces";
import { MapImageForPlace } from "../maps/MapImageForPlace";
import { MarkerVisualizations } from "../maps/SvgUtils";
import GenericInfoComponent from "../others/GenericInfoComponent";
import NearbyPlacesRadiusComponent from "../places/NearbyPlacesRadiusComponent";
import { ScrapeLinksQueryListAccordion } from "../scrape_link/ScrapeLinksQueryListAccordion";
import { useMediaQuery } from "react-responsive";
import { FeaturesInfo } from "../info/features/FeaturesInfo";
import { useUserService } from "../../services/UserService";
type IProps = {
  placeObject: IPlace;
  showTitle?: boolean;
  hideFlyTo?: boolean;
  showInfo?: boolean;
};

export default function PlaceLarge({
  placeObject,
  showTitle = true,
  hideFlyTo = false,
  showInfo = false,
}: IProps) {
  const { t } = useTranslationService();
  const {isSuperuser} = useUserService();
  const isBigScreen = useMediaQuery({ query: "(min-width: 550px)" });
  const placeId = placeObject.Id;
  // const { onePlaceActions } = usePlaceActions(placeObject, hideFlyTo);
  const [title, setTitle] = useState<String | undefined>(undefined);
  const [MapImageComponent, setMapImageComponent] = useState<any>(null);
  const [CountryImageComponent, setCountryImageComponent] = useState<any>(null);
  const [nearbyComponent, setNearbyComponent] = useState<any>(null);
  // const [placelistsWithPlaceComp, setPlacelistsWithPlaceComp] =
  //   useState<any>(null);
  const titleContext = useContext(TitleContext);

  useEffect(() => {
    if (!isNullOrUndefined(placeObject)) {
      if (!isNullOrUndefined(placeObject.Name)) {
        setTitle(placeObject.Name);
      }
      const mapImage = (
        <MapImageForPlace place={placeObject} width="300px" height="300px" />
      );
      setMapImageComponent(mapImage);

      const countryImage = (
        <CountriesMapForPlaces
          places={[placeObject]}
          disableMarkerClustering={true}
          markerVisualization={MarkerVisualizations.circle}
          width="300px"
          height="300px"
        />
      );
      setCountryImageComponent(countryImage);
      const nbc = <NearbyPlacesRadiusComponent place={placeObject} />;
      setNearbyComponent(nbc);
      // const plwp = (
      //   <PlacelistsWithPlace
      //     key={"PlacelistsWithPlace_" + placeObject.Id}
      //     place={placeObject}
      //   />
      // );
      // setPlacelistsWithPlaceComp(plwp);
      // }
    }
  }, [placeObject]);

  useEffect(() => {
    if (title) {
      if (titleContext) {
        titleContext.setTitle(title);
      }
      // window.document.title = title;
    }
  }, [title, titleContext]);

  let titleComponent = null;
  if (showTitle) {
    titleComponent = isNullOrUndefined(title) ? (
      <Typography variant="h3"></Typography>
    ) : (
      <Typography variant="h3">{title}</Typography>
    );
  }

  const scrapeLinksComponent = useMemo(() => {
    if (showInfo) {
      const queryArgs = { placeId: placeId };
      return (
        <ScrapeLinksQueryListAccordion
          header={t("scrape-links")}
          queryArguments={queryArgs}
        />
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [placeId, showInfo]);

  const genericInfo = isSuperuser && <GenericInfoComponent
    key={"GenericInfoComponent_" + placeId}
    dataObject={placeObject}
    hide={true}
    open={false} />;
  // const actionBar = useMemo(() => {
  //   return <SmartActionBar actions={onePlaceActions} showLabels={false} />;
  // }, [onePlaceActions]);

  return (
    <Stack key={"Stack" + placeId} spacing={1}>
      {showTitle ? titleComponent : null}
      <Stack
        key="stack_for_maps_and_info"
        // flexGrow={1}
        display="flex"
        direction={isBigScreen ? "row" : "column"}
        flexWrap="wrap"
        // columnGap={2}
        gap={1}
      >
        <Stack
          key="stack_for_maps"
          direction={isBigScreen ? "row" : "column"}
          spacing={{ xs: 1, sm: 2 }}
          flexWrap="wrap"
          useFlexGap
        >
          {MapImageComponent}
          {CountryImageComponent}
        </Stack>
        <PlaceInfo place={placeObject} hideFlyTo={hideFlyTo} />
        <Stack
          key="stack_for_features"
          // direction={isBigScreen ? "row" : "column"}
          direction={"column"}
          gap={1}
          flexWrap="wrap"
          useFlexGap
        >
          <FeaturesInfo place={placeObject} />
          <FeaturesInfo place={placeObject} available={false} />
        </Stack>
      </Stack>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="flex-start"
        flexGrow={1}
        useFlexGap
      />
      {/* {actionBar} */}
      {nearbyComponent}
      {/* {placelistsWithPlaceComp} */}
      {scrapeLinksComponent}
      {genericInfo}
    </Stack>
  );
}

// export default memo(PlaceLarge);
