import Textfit from "@namhong2001/react-textfit";
import React from "react";
interface IProps {
  mode?: "single" | "multi";
  text: string;
  textColor: string;
  minSize?: number;
  maxSize?: number;
  width?: string;
  height?: string;
  textShadow?: any;
}
export function SmartOverlayText({
  mode = "multi",
  text,
  textColor,
  minSize = 10,
  maxSize = 100,
  width = "90%",
  height = "90%",
  textShadow,
}: IProps): JSX.Element | null {
  return (
    <Textfit
      mode={mode}
      min={minSize}
      max={maxSize}
      style={{
        position: "absolute",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        width: width,
        height: height,
        textAlign: "center",
        color: textColor,
        backgroundColor: "none",
        textShadow: textShadow,
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 999,
      }}
    >
      {text}
    </Textfit>
  );
}
