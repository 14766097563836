import { useState, useEffect } from "react";
import { useGetPlaceJoinedQuery } from "../../api/PlacesApiSlice";
import { Typography } from "@mui/material";
import React from "react";
import PlaceSmall from "./PlaceSmall";

type IProps = {
  placeId: string;
  parent?: any;
  enableFlyTo?: boolean;
  fromLatiude?: number;
  fromLongitude?: number;
  infoVisibility?: "expanded" | "collapsed" | "hidden";
};

function PlaceSmallFromId(props: IProps) {
  const { data: placeQueryResult } = useGetPlaceJoinedQuery(props.placeId);
  const [placeObject, setPlaceObject] = useState(null);

  useEffect(() => {
    if (placeQueryResult) {
      if (placeQueryResult.records && placeQueryResult.records[0]) {
        const placeDataObject = placeQueryResult.records[0];
        setPlaceObject(placeDataObject);
      }
    }
  }, [placeQueryResult]);

  let ret = null;
  if (placeObject) {
    const childProps = { placeObject, ...props };
    ret = <PlaceSmall {...childProps} />;
  } else {
    ret = <Typography>Lade Platz #{props.placeId}</Typography>;
  }
  return ret;
}

export default PlaceSmallFromId;
