import { useTranslation } from "react-i18next";

function useTranslationService() {
  const { t, i18n } = useTranslation();
  function fromSecondsToTimeString(s: number) {
    return fromMilisecondsToTimeString(s * 1000);
  }

  function fromMilisecondsToTimeString(ms: number) {
    var ret;
    if (ms < 1000) {
      // less than a second
      ret = ms + " " + t("time-ms");
    } else if (ms < 10000) {
      // less than 10 seconds
      ret = (ms / 1000).toFixed(1) + " " + t("time-s");
    } else if (ms < 60000) {
      //less than a minute
      ret = (ms / 1000).toFixed(0) + " " + t("time-s");
    } else if (ms < 600000) {
      // less than 10 minutes
      const seconds = Math.round(ms / 1000);
      const fullMinutes = Math.floor(seconds / 60).toFixed(0);
      const fullSeconds = Math.round(seconds % 60).toFixed(0);
      ret = fullMinutes;
      if (fullSeconds !== "0") {
        ret = ret + ":" + fullSeconds.padStart(2, "0");
      }
      ret = ret + " " + t("time-min");
    } else if (ms < 3600000) {
      // less than 1 hour
      const minutes = (ms / 60000).toFixed(0);
      ret = minutes + " " + t("time-min");
    } else if (ms < 3600000 * 24) {
      // less than a day
      const minutes = Math.round(ms / 60000);
      const fullHours = Math.floor(minutes / 60).toFixed(0);
      const fullMinutes = Math.floor(minutes % 60).toFixed(0);
      // const fullSeconds = Math.round(seconds % 3600).toFixed(0);
      ret = fullHours;
      if (fullMinutes !== "0") {
        ret = ret + ":" + fullMinutes.padStart(2, "0");
      }
      ret = ret + " " + t("time-h");
    } else {
      // more than a day
      const hours = Math.round(ms / 3600000);
      const fullDays = Math.floor(hours / 24).toFixed(0);
      const fullHours = Math.round(hours % 24).toFixed(0);
      ret = fullDays + " " + t("time-days");
      if (fullHours !== "0") {
        ret = ret + " " + t("and") + " " + fullHours + " " + t("time-h");
      }
    }
    return ret;
  }

  function tCapitalize(
    key: any | string | string[],
    params?: any | string
  ): string {
    return i18n.format(t(key, params), "capitalize");
  }

  function tCountryNameFromCountryCode(countryCode: string): string {
    // console.log("tCountryNameFromCountryCode", countryCode);
    const currentLanguage = i18n.language;
    return getCountryNameTranslatedFromCountryCode(
      countryCode,
      currentLanguage
    );
  }

  function tLanguageNameFromLanguageCode(languageCode: string): string {
    // console.log("tLanguageNameFromLanguageCode", languageCode);
    const currentLanguage = i18n.language;
    return getLanguageNameTranslatedFromLanguageCode(
      languageCode,
      currentLanguage
    );
  }

  return {
    t,
    i18n,
    tCapitalize,
    tCountryNameFromCountryCode,
    tLanguageNameFromLanguageCode,
    fromSecondsToTimeString,
    fromMilisecondsToTimeString,
  };
}

export function getCountryNameTranslatedFromCountryCode(
  countryCode: string,
  language: string
) {
  let ret;
  var countries = require("i18n-iso-countries");
  const shortened = language.substring(0, 2);
  try {
    countries.registerLocale(
      require("i18n-iso-countries/langs/" + shortened + ".json")
    );
    ret = countries.getName(countryCode, shortened);
  } catch (err) {
    console.warn("no language file found for language code " + language, err);
  }
  if (ret == null || ret.length < 1) {
    return countryCode;
  }
  return ret;
}

export function getLanguageNameTranslatedFromLanguageCode(
  languageCode: string,
  language: string
): string {
  let ret;
  const languageNames = new Intl.DisplayNames([language], {
    type: "language",
  });
  ret = languageNames.of(languageCode);
  if (ret == null || ret.length < 1) {
    return languageCode;
  }
  return ret;
}

export default useTranslationService;
