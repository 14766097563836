import { EuroTwoTone } from "@mui/icons-material";
import React from "react";
import useTranslationService from "../../services/TranslationService";
import { numberToMoney } from "../../utils/Utils";
import { SmartChip } from "../chip/SmartChip";

type Props = {
  price: number;
  originalPlacePrice?: string;
};

export function PriceChip({ price, originalPlacePrice }: Props) {
  const { t } = useTranslationService();
  const money = numberToMoney(price);
  return (
    <SmartChip
      text={money}
      icon={<EuroTwoTone />}
      tooltip={pimpWithOriginalPriceInfo(money)}
    />
  );

  function pimpWithOriginalPriceInfo(text: string) {
    let ret: string | any = text;
    if (originalPlacePrice != null && originalPlacePrice.length > 0) {
      const secondLine =
        t("price-according-provider") + ': "' + originalPlacePrice + '"';
      const allText = text + " - " + secondLine;
      ret = <div style={{ whiteSpace: "pre-line" }}>{allText}</div>;
    }
    return ret;
  }
}
